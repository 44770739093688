const createRequestDataReducer = (key) => (state, action, type = 'fulfilled') => {
  const newState = { ...state[key], loading: false, error: null };

  if (type === 'fulfilled') {
    newState.data = action.payload.data;
    newState.message = action.payload.message;
    newState.status = action.payload.status;
  } else if (type === 'pending') {
    newState.loading = true;
    newState.message = 'Not Loaded';
    newState.status = true;
  } else {
    newState.status = false;
    newState.error = action?.error?.message;
    newState.data = action?.error?.message;
  }

  return { ...state, [key]: { ...newState } };
};

export const getPageBlockData = (state) => state?.pageBlock?.pageBlocks;
export const getAddressAndCoordinateData = (state) => state?.pageBlock?.addressAndCoordinates;
export const getSubHeaderData = (state) => state?.pageBlock?.subHeader;
export const getLoginSliderData = (state) => state?.pageBlock?.loginSlider;
export const getUserCouponsData = (state) => state?.pageBlock?.userCoupons;
export const getSplashScreenData = (state) => state?.pageBlock?.splashScreen;

export const requestDataForPageBlockData = createRequestDataReducer('pageBlocks');
export const requestDataForAddressAndCoordinaes = createRequestDataReducer('addressAndCoordinates');
export const requestDataForSubHeader = createRequestDataReducer('subHeader');
export const requestDataForLoginSlider = createRequestDataReducer('loginSlider');
export const requestDataForUserCoupon = createRequestDataReducer('userCoupons');
