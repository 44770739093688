import { CLUB_STORE, CITIES, DINE_OUTS, CATEGORYWISE_DINE_OUTS, SINGLE_DINE_INFO, DINE_OUT_PAYMENT, DINE_PAYMENT_STATUS, DINE_OUT_BILLING, GET_DINE_OUT_REVIEWS, GET_DINING_BANNER } from "./actionTypes";

const initialState = {
    club_stores: [],
    cities: [],
    popular_dineouts: [],
    featured_dinouts: [],
    dineout_categories: [],
    new_dinouts: [],
    all_dineouts: [],
    dine_outs_category: [],
    categeory_stores: [],
    single_dine_info: [],
    dine_out_payment_info: [],
    dine_payment_info: {},
    balance: 0,
    dine_reviews: {},
    dining_banner: []
};

export default function (state = initialState, action) {
    switch (action.type) {
        case CLUB_STORE:
            return { ...state, club_stores: action.payload };
        case CITIES:
            return { ...state, cities: action.payload };
        case DINE_OUTS:
            return {
                ...state,
                popular_dineouts: action.payload.popular_dineouts,
                featured_dinouts: action.payload.featured_dinouts,
                dineout_categories: action.payload.dineout_categories,
                new_dinouts: action.payload.new_dinouts,
                all_dineouts: action.payload.all_dineouts,
            };
        case DINE_OUT_BILLING:
            return {
                ...state,
                popular_dineouts: action.payload.popular_dineouts,
                featured_dinouts: action.payload.featured_dinouts,
                dineout_categories: action.payload.dineout_categories,
                new_dinouts: action.payload.new_dinouts,
                all_dineouts: action.payload.all_dineouts,
                balance: action.payload.balance,
            };
        case CATEGORYWISE_DINE_OUTS:
            return {
                ...state,
                dine_outs_category: action.payload.dine_outs_category,
                categeory_stores: action.payload.categeory_stores
            };
        case SINGLE_DINE_INFO:
            return { ...state, single_dine_info: action.payload };
        case DINE_OUT_PAYMENT:
            return { ...state, dine_out_payment_info: action.payload };
        case DINE_PAYMENT_STATUS:
            return { ...state, dine_payment_info: action.payload };
        case GET_DINE_OUT_REVIEWS:
            return { ...state, dine_reviews: action.payload };
        case GET_DINING_BANNER:
            return { ...state, dining_banner: action.payload };
        default:
            return state;
    }
}