import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { is_local } from "../../configs";
import { API_URL } from "../../configs/website";

const defaultPageBlocks = {
  loading: false,
  data: null,
  message: 'Not Loaded',
  error: null,
  status: false,
};

export const clearPageBlocks = (state) => {
  return { ...state, pageBlocks: { ...defaultPageBlocks } };
};

export const clearAddressAndCoordinates = (state) => {
  return { ...state, addressAndCoordinates: { ...defaultPageBlocks } };
};

export const clearSubHeader = (state) => {
  return { ...state, subHeader: { ...defaultPageBlocks } };
};

export const clearLoginSlider = (state) => {
  return { ...state, loginSlider: { ...defaultPageBlocks } };
};

export const clearUserCoupons = (state) => {
  return { ...state, userCoupons: { ...defaultPageBlocks } };
};

export const clearSplashScreen = (state) => {
  return { ...state, splashScreen: { ...defaultPageBlocks } };
};

export const getPageBlocksRequest = createAsyncThunk('page-at-home/get-page-blocks-request', async ({ data }) => {
  try {
    const pageBlocks = await axios.post(`${API_URL}/get-page-at-home-blocks?is_local=${is_local === true ? 1 : 0}`, data);
    return { ...pageBlocks.data };
  } catch (error) {
    return {};
  }
});

export const getAddressAndCoordinatesFromPlaceIdRequest = createAsyncThunk('page-at-home/get-address-and-coordinates-from-place-id-request', async ({ data }) => {
  try {
    const pageBlocks = await axios.post(`${API_URL}/get-address-and-coordinates-from-place-id`, data);
    return { ...pageBlocks.data };
  } catch (error) {
    return {};
  }
});

export const getHomePageSubHeadRequest = createAsyncThunk('page-at-home/get-home-page-sub-head-request', async () => {
  try {
    const subHeader = await axios.post(`${API_URL}/get-home-page-sub-header`);
    return { ...subHeader.data };
  } catch (error) {
    return {};
  }
});

export const getLoginSliderRequest = createAsyncThunk('page-at-home/get-login-slider-request', async () => {
  try {
    const loginSlider = await axios.post(`${API_URL}/get-login-slider`);
    return { ...loginSlider.data };
  } catch (error) {
    return {};
  }
});

export const getUserCouponsRequest = createAsyncThunk('page-at-home/get-user-coupon-request', async ({ data }) => {
  try {
    const userCoupons = await axios.post(`${API_URL}/get-user-coupons`, data);
    return { ...userCoupons.data };
  } catch (error) {
    return {};
  }
});
