import { USER_COIN_COMBO_PURCHASE, USER_COIN_PURCHASE, CURRENT_COIN_RATE } from "./actionTypes";

const initialState = {
    user_coin_combo_purchase: [],
    user_coin_purchase: {},
    current_coin_rate: {}
};

export default function(state = initialState, action) {
    switch (action.type) {
        case USER_COIN_COMBO_PURCHASE:
            return { ...state, user_coin_combo_purchase: action.payload };
        case USER_COIN_PURCHASE:
            return { ...state, user_coin_purchase: action.payload };
        case CURRENT_COIN_RATE:
            return { ...state, current_coin_rate: action.payload };
        default:
            return state;
    }
}