import { CLUB_STORE_WALLET_REQUEST,
        CLUB_STORE_WALLET_REQUEST_STATUS,
        CLUB_STORE_WALLET_REQUEST_HISTORY } from "./actionTypes";

const initialState = {
    club_store_wallet_request: [],
    club_store_wallet_request_status: {},
    club_store_wallet_request_history: []
};

export default function(state = initialState, action) {
    // console.log(action.payload, 'payload get club stories');
    if(action.type === CLUB_STORE_WALLET_REQUEST) {
        return { ...state, club_store_wallet_request: action.payload };
    } else if(action.type === CLUB_STORE_WALLET_REQUEST_STATUS) {
        return { ...state, club_store_wallet_request_status: action.payload };
    } else if(action.type === CLUB_STORE_WALLET_REQUEST_HISTORY) {
        return { ...state, club_store_wallet_request_history: action.payload };
    } else {
        return state;
    }
}
