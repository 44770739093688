import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { UPDATE_USER_INFO_URL } from "../../configs";
import { API_URL } from "../../configs/website";

export const defaultPageBlocks = {
  loading: false,
  data: null,
  message: 'Not Loaded',
  error: null,
  status: false,
};

export const clearOtpRequest = (state) => {
  return { ...state, otpRequest: { ...defaultPageBlocks } };
};

export const clearOtpVerify = (state) => {
  return { ...state, otpVerify: { ...defaultPageBlocks } };
};

export const clearOtpNewUser = (state) => {
  return { ...state, otpNewUser: { ...defaultPageBlocks } };
};

export const clearLoggedUserInfo = (state) => {
  return { ...state, loggedUserInfo: { ...defaultPageBlocks } };
};

export const setOtpSendRequest = createAsyncThunk('otp-user/otp-for-customer-request', async ({ data }) => {
  try {
    const otpRequest = await axios.post(`${API_URL}/otp-for-customer-2`, data);
    return { ...otpRequest.data };
  } catch (error) {
    return {};
  }
});

export const sendOtpVerifyRequest = createAsyncThunk('otp-user/send-otp-verify-request', async ({ data }) => {
  try {
    const otpVerify = await axios.post(`${API_URL}/verify-otp-user`, data);
    return { ...otpVerify.data };
  } catch (error) {
    return {};
  }
});

export const registerNewUserRequest = createAsyncThunk('otp-user/register-new-user-request', async ({ data }) => {
  try {
    const otpNewUser = await axios.post(`${API_URL}/register-new-user-from-otp`, data);
    return { ...otpNewUser.data };
  } catch (error) {
    return {};
  }
});

export const loggedUserDataRequest = createAsyncThunk('otp-user/logged-user-data-request', async ({ data }) => {
  try {
    const loggedUserInfo = await axios.post(`${UPDATE_USER_INFO_URL}`, data);
    return { ...loggedUserInfo.data };
  } catch (error) {
    return {};
  }
});
