import { useEffect, useState } from "react";
import ReactGA from 'react-ga4';
import { useDispatch } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router";
import { SystemContext, handleClearCache } from "./components/helpers/truncate";
// import FreshChat from 'react-freshchat';
import axios from "axios";
import Network from "./Network";
import "./assets/css/grid.css";

const WithRouterPass = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const params = useParams();
  // const user = useSelector(state => state.user.user);

  const [title, setTitle] = useState(document.title);

  useEffect(() => {
    ReactGA.initialize('G-GDZJC4D3D6', {
      testMode: false
    });
    // ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: title });
    // console.log({hitType: "pageview", page: window.location.pathname, title: title});
  }, []);

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: title });
  }, [title]);

  const updateCahce = async (callBack = undefined, type = 'normal') => {
    if (type === 'normal') {
      axios.get("/version.json").then(async (response) => {
        const forceNewSettingsServerVersion = response.data.forceNewSettingsVersion;
        const forceCacheClearServerVersion = response.data.forceCacheClearVersion;
        if (
          localStorage.getItem("forceNewSettingsVersion") !== forceNewSettingsServerVersion &&
          localStorage.getItem("forceCacheClearVersion") !== forceCacheClearServerVersion
        ) {
          localStorage.setItem("forceNewSettingsVersion", forceNewSettingsServerVersion);
          localStorage.setItem("forceCacheClearVersion", forceCacheClearServerVersion);
          console.log('version reloaded. set');
          try {
            await handleClearCache();
          } catch (error) {
            window.location.reload();
          }
          if (callBack) callBack();
        }
      })
        .catch(() => {
          if (callBack) callBack();
        });
    } else {
      await handleClearCache();
      if (callBack) callBack();
    }
  };

  return (
    <SystemContext.Provider value={{ navigate, dispatch, setTitle, location, params, updateCahce }}>
      <Network>
        {props.component ?
          <props.component
            {...props}
            navigate={navigate}
            dispatch={dispatch}
            match={{ params: params }} location={location} setTitle={setTitle} updateCahce={updateCahce}
          />
          : <></>}
      </Network>
    </SystemContext.Provider>
  );
}

export default WithRouterPass;
