import { createSlice } from '@reduxjs/toolkit';
import * as otpUserActions from './otpUserActions';
import * as otpUserReducerData from './otpUserReducerData';
const initialState = {
  otpRequest: { ...otpUserActions.defaultPageBlocks },
  otpVerify: { ...otpUserActions.defaultPageBlocks },
  otpNewUser: { ...otpUserActions.defaultPageBlocks },
  loggedUserInfo: { ...otpUserActions.defaultPageBlocks },
};

const otpUserSlice = createSlice({
  name: 'otpUser',
  initialState,
  reducers: {
    clearOtpRequest: (state) => otpUserActions.clearOtpRequest(state),
    clearOtpVerify: (state) => otpUserActions.clearOtpVerify(state),
    clearOtpNewUser: (state) => otpUserActions.clearOtpNewUser(state),
    clearLoggedUserInfo: (state) => otpUserActions.clearLoggedUserInfo(state),
  },
  extraReducers: (builder) => {
    builder
      .addCase(otpUserActions.setOtpSendRequest.pending, (state, action) =>
        otpUserReducerData.requestDataForOtpRequest(state, action, 'pending'),
      )
      .addCase(otpUserActions.setOtpSendRequest.fulfilled, (state, action) =>
        otpUserReducerData.requestDataForOtpRequest(state, action, 'fulfilled'),
      )
      .addCase(otpUserActions.setOtpSendRequest.rejected, (state, action) =>
        otpUserReducerData.requestDataForOtpRequest(state, action, 'rejected'),
      );

    builder
      .addCase(otpUserActions.sendOtpVerifyRequest.pending, (state, action) =>
        otpUserReducerData.requestDataForOtpVerify(state, action, 'pending'),
      )
      .addCase(otpUserActions.sendOtpVerifyRequest.fulfilled, (state, action) =>
        otpUserReducerData.requestDataForOtpVerify(state, action, 'fulfilled'),
      )
      .addCase(otpUserActions.sendOtpVerifyRequest.rejected, (state, action) =>
        otpUserReducerData.requestDataForOtpVerify(state, action, 'rejected'),
      );

    builder
      .addCase(otpUserActions.registerNewUserRequest.pending, (state, action) =>
        otpUserReducerData.requestDataForOtpNewuser(state, action, 'pending'),
      )
      .addCase(otpUserActions.registerNewUserRequest.fulfilled, (state, action) =>
        otpUserReducerData.requestDataForOtpNewuser(state, action, 'fulfilled'),
      )
      .addCase(otpUserActions.registerNewUserRequest.rejected, (state, action) =>
        otpUserReducerData.requestDataForOtpNewuser(state, action, 'rejected'),
      );

      builder
      .addCase(otpUserActions.loggedUserDataRequest.pending, (state, action) =>
        otpUserReducerData.requestDataForLoggedUser(state, action, 'pending'),
      )
      .addCase(otpUserActions.loggedUserDataRequest.fulfilled, (state, action) =>
        otpUserReducerData.requestDataForLoggedUser(state, action, 'fulfilled'),
      )
      .addCase(otpUserActions.loggedUserDataRequest.rejected, (state, action) =>
        otpUserReducerData.requestDataForLoggedUser(state, action, 'rejected'),
      );
  },
});

export const {
  clearOtpRequest,
  clearOtpVerify,
  clearOtpNewUser,
  clearLoggedUserInfo,
} = otpUserSlice.actions;

export default otpUserSlice.reducer;
