import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { is_local } from "../../configs";
import { WEBSITE_URL } from "../../configs/website";
const defaultDineIn = {
  loading: false,
  data: null,
  message: 'Not Loaded',
  error: null,
  status: false,
};

export const clearDineInCounts = (state) => {
  return { ...state, dineInCount: { ...defaultDineIn } };
};

export const clearDineInCategories = (state) => {
  return { ...state, dineInCategory: { ...defaultDineIn } };
};

export const clearDineInFeatured = (state) => {
  return { ...state, dineInFeatured: { ...defaultDineIn } };
};

export const getDineInCountRequest = createAsyncThunk('dine-ins/get-dine-in-count-request', async ({ data }) => {
  try {
    const dineInCount = await axios.post(`${WEBSITE_URL}/public/api/dine-in-counts?is_local=${is_local === true ? 1 : 0}`, data);
    return { ...dineInCount.data };
  } catch (error) {
    return {};
  }
});

export const getDineInCategoryRequest = createAsyncThunk('dine-ins/get-dine-in-category-request', async ({ data }) => {
  try {
    const dineInCategory = await axios.post(`${WEBSITE_URL}/public/api/dine-in-categories?is_local=${is_local === true ? 1 : 0}`, data);
    return { ...dineInCategory.data };
  } catch (error) {
    return {};
  }
});

export const getDineInFeaturedRequest = createAsyncThunk('dine-ins/get-dine-in-featured-request', async ({ data }) => {
  try {
    const dineInCategory = await axios.post(`${WEBSITE_URL}/public/api/dine-in-featured?is_local=${is_local === true ? 1 : 0}`, data);
    return { ...dineInCategory.data };
  } catch (error) {
    return {};
  }
});

export const getDineInCollectionRequest = createAsyncThunk('dine-ins/get-dine-in-collection-request', async ({ data }) => {
  try {
    const dineInCollection = await axios.post(`${WEBSITE_URL}/public/api/dine-in-collection?is_local=${is_local === true ? 1 : 0}`, data);
    return { ...dineInCollection.data };
  } catch (error) {
    return {};
  }
});
