import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { API_URL } from '../../configs/website';
import * as freshReducerData from './freshReducerData';

const initialState = {
  fresh_list_items: {
    loading: false,
    data: null,
    message: 'Not Loaded',
    error: null,
    status: false,
  },
  fresh_list_categories: {
    loading: false,
    data: null,
    message: 'Not Loaded',
    error: null,
    status: false,
  },
};

export const getFreshListItemsRequest = createAsyncThunk('get-fresh/get-fresh-list-items', async ({ data }) => {
  try {
    const fresh_list_items = await axios.post(`${API_URL}/get-fresh-list-items`, data);
    return { ...fresh_list_items.data };
  } catch (error) {
    return {};
  }
});

export const getFreshItemCategoriesRequest = createAsyncThunk('get-fresh/get-fresh-item-categories', async ({ data }) => {
  try {
    const fresh_list_categories = await axios.post(`${API_URL}/get-fresh-list-categories`, data);
    return { ...fresh_list_categories.data };
  } catch (error) {
    return {};
  }
});

const freshListSlice = createSlice({
  name: 'fresh_list',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getFreshListItemsRequest.pending, (state, action) => {
        freshReducerData.requestDataForFreshList(state, action, 'pending');
      })
      .addCase(getFreshListItemsRequest.fulfilled, (state, action) => {
        freshReducerData.requestDataForFreshList(state, action, 'fulfilled');
      })
      .addCase(getFreshListItemsRequest.rejected, (state, action) => {
        freshReducerData.requestDataForFreshList(state, action, 'rejected');
      });

    builder
      .addCase(getFreshItemCategoriesRequest.pending, (state, action) => {
        freshReducerData.requestDataForFreshItemCategory(state, action, 'pending');
      })
      .addCase(getFreshItemCategoriesRequest.fulfilled, (state, action) => {
        freshReducerData.requestDataForFreshItemCategory(state, action, 'fulfilled');
      })
      .addCase(getFreshItemCategoriesRequest.rejected, (state, action) => {
        freshReducerData.requestDataForFreshItemCategory(state, action, 'rejected');
      });
  },
});

export default freshListSlice.reducer;
