import { YUMMY_SPOTTED_NEAR, YUMMY_SPOTTED_SINGLE, YUMMY_SPOTTED_REVIEW, YUMMY_SPOTTED_LIKE } from './actionType';

const initialState = {
  yummy_near: [],
  yummy_single: {},
  yummy_review: {},
  yummy_like: {},
};

export default function (state = initialState, action) {
  // console.log(action.type)
  switch (action.type) {
    case YUMMY_SPOTTED_NEAR:
      return { ...state, yummy_near: action.payload };
    case YUMMY_SPOTTED_SINGLE:
      return { ...state, yummy_single: action.payload };
    case YUMMY_SPOTTED_REVIEW:
      return { ...state, yummy_review: action.payload };
    case YUMMY_SPOTTED_LIKE:
      return { ...state, yummy_like: action.payload };
    default:
      return state;
  }
}
