import { GET_MAPBOX_TOKEN, GET_MAPS_TOKENS, GET_POPULAR_LOCATIONS } from "./actionTypes";

const initialState = {
    popular_locations: [],
    mapbox_token: "",
    maps_tokens: {},
};

export default function(state = initialState, action) {
    switch (action.type) {
        case GET_POPULAR_LOCATIONS:
            return { ...state, popular_locations: action.payload };
        case GET_MAPBOX_TOKEN:
            return { ...state, mapbox_token: action.payload };
        case GET_MAPS_TOKENS:
            return { ...state, maps_tokens: action.payload };
        default:
            return state;
    }
}
