import { combineReducers } from "redux";
import acceptToDeliver from "./Delivery/deliveryprogress/reducer";
import gpsLocationReducer from "./Delivery/gpslocation/reducer";
import deliveryOrdersReducer from "./Delivery/orders/reducer";
import deliverySingleOrderReducer from "./Delivery/singleorder/reducer";
import deliveryUserReducer from "./Delivery/user/reducer";
import getClubStoreBanners from "./Store/clubBanners/reducer";
import getClubStoreMenus from "./Store/clubMenus/reducer";
import getClubStoreDetails from "./Store/clubStore/reducer";
import getClubStoreStories from "./Store/clubStories/reducer";
import clubUserReducer from "./Store/clubUser/reducer";
import getClubStoreWallet from "./Store/clubWallet/reducer";
import getClubStoreWalletRequest from "./Store/clubWalletRequest/reducer";
import restaurantPayoutReports from "./Store/payouts/reducer";
import registerRestaurant from "./Store/register/reducer";
import storeUserReducer from "./Store/user/reducer";
import addressesReducer from "./addresses/reducer";
import alertReducer from "./alert/reducer";
import billBoardSlidesReducer from "./billBoards/reducer";
import cartReducer from "./cart/reducer";
import checkoutReducer from "./checkout/reducer";
import clubStoreReducer from "./club/clubStores/reducer";
import clubCusineReducer from "./club/cusines/reducer";
import clubPlansReducer from "./club/plans/reducer";
import clubUserCardReducer from "./club/userCard/reducer";
import userCoinComboPurchase from "./club/userCoinComboPurchase/reducer";
import clubUserPlanReducer from "./club/userplan/reducer";
import confirmCartReducer from "./confirmCart/reducer";
import couponReducer from "./coupon/reducer";
import dietPlanReducers from "./diet/dietReducers";
import dineInReducers from "./dineIn/dineInReducers";
import feedbackUsers from "./feedback/reducer";
import foodSpotReducers from "./foodSpots/foodSpotReducers";
import freshLists from "./fresh/reducer";
import homePageReducer from "./homepage/reducer";
import itemsReducer from "./items/reducer";
import languageReducer from "./languages/reducer";
import locationsReducer from "./locations/reducer";
import notificationReducer from "./notification/reducer";
import ordersReducer from "./orders/reducer";
import otpUserReducers from "./otpUser/otpUserReducers";
import pageAtHomeReducers from "./pageAtHome/pageAtHomeReducers";
import pagesReducer from "./pages/reducer";
import paymentGatewayReducer from "./paymentgateways/reducer";
import popularLocationReducer from "./popularLocations/reducer";
import promoSliderReducer from "./promoSlider/reducer";
import ratingReducer from "./rating/reducer";
import recipe from "./recipe/actions";
import restaurantReducer from "./restaurant/reducer";
import restaurantSearchReducer from "./searchRestaurants/reducer";
import settingsReducer from "./settings/reducer";
import totalReducer from "./total/reducer";
import userReducer from "./user/reducer";
import yummySpottedReducer from "./yummySpotted/reducer";

export default combineReducers({
  settings: settingsReducer,
  locations: locationsReducer,
  popular_locations: popularLocationReducer,
  promo_slides: promoSliderReducer,
  bill_board_slides: billBoardSlidesReducer,
  items: itemsReducer,
  total: totalReducer,
  cart: cartReducer,
  coupon: couponReducer,
  user: userReducer,
  pages: pagesReducer,
  restaurants: restaurantSearchReducer,
  addresses: addressesReducer,
  checkout: checkoutReducer,
  confirmCart: confirmCartReducer,
  orders: ordersReducer,
  paymentgateways: paymentGatewayReducer,
  delivery_user: deliveryUserReducer,
  delivery_orders: deliveryOrdersReducer,
  single_delivery_order: deliverySingleOrderReducer,
  gps_location: gpsLocationReducer,
  accepted_order: acceptToDeliver,
  notification_token: notificationReducer,
  restaurant: restaurantReducer,
  languages: languageReducer,
  rating: ratingReducer,
  alert: alertReducer,
  store_user: storeUserReducer,
  home_page: homePageReducer,
  club_cusines: clubCusineReducer,
  club_plans: clubPlansReducer,
  club_stores: clubStoreReducer,
  yummy_spotted: yummySpottedReducer,
  club_user: clubUserReducer,
  club_user_plans: clubUserPlanReducer,
  club_user_card: clubUserCardReducer,
  user_coin_combo_purchase: userCoinComboPurchase,
  club_store_stories: getClubStoreStories,
  club_store_banners: getClubStoreBanners,
  club_store_menus: getClubStoreMenus,
  club_store_wallet: getClubStoreWallet,
  club_store_wallet_request: getClubStoreWalletRequest,
  club_store_details: getClubStoreDetails,
  restaurant_payouts: restaurantPayoutReports,
  feedback: feedbackUsers,
  fresh_list: freshLists,
  register_restaurant: registerRestaurant,
  recipes: recipe,
  pageBlock: pageAtHomeReducers,
  foodSpot: foodSpotReducers,
  dietPlan: dietPlanReducers,
  otpUser: otpUserReducers,
  dineIns: dineInReducers,
});
