import { CLUB_STORE_RESTAURANT_DETAILS } from "./actionTypes";

const initialState = {
    club_store_details: {}
};

export default function(state = initialState, action) {
    // console.log(action.payload, 'payload get club stories');
    if(action.type === CLUB_STORE_RESTAURANT_DETAILS) {
        return { ...state, club_store_details: action.payload };
    } else {
        return state;
    }
}
