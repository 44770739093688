import { GET_ALL_LANGUAGES, GET_SERVER_VERIFY_TOKEN_DATA, GET_SINGLE_LANGUAGE_DATA } from "./actionTypes";

const initialState = {
    languages: [],
    language: [],
    token: {}
};

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_ALL_LANGUAGES:
            return { ...state, languages: action.payload };
        case GET_SINGLE_LANGUAGE_DATA:
            return { ...state, language: action.payload };
        case GET_SERVER_VERIFY_TOKEN_DATA:
            return { ...state, token: action.payload };
        default:
            return state;
    }
}
