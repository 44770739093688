// import { UPDATE_CART, UPDATE_CART_COIN, UPDATE_CART_COIN_TOTAL } from "./actionTypes";

import actions from "./actions";

// const initialState = {
//     data: {
//         productQuantity: 0,
//         totalPrice: 0
//     },

//     coin_data: {
//         productQuantity: 0,
//         totalPrice: 0,
//         totalCoins: 0
//     },

//     coin_total: {
//         productQuantity: 0,
//         totalPrice: 0,
//         coinsToPay: 0,
//         gainCoins: 0,
//         minusCoins: 0
//     }
// };

// export default function (state = initialState, action) {
//     switch (action.type) {
//         case UPDATE_CART:
//             return {
//                 ...state,
//                 data: action.payload
//             };

//         case UPDATE_CART_COIN:
//             return {
//                 ...state,
//                 coin_data: action.payload,
//             };
//         case UPDATE_CART_COIN_TOTAL:
//             return {
//                 ...state,
//                 coin_total: action.payload,
//             };
//         default:
//             return state;
//     }
// }

export default actions;