import { createSlice } from '@reduxjs/toolkit';
import * as pageAtHomeActions from './pageAtHomeActions';
import * as pageAtHomeReducerData from './pageAtHomeReducerData';

const initialState = {
  pageBlocks: {
    loading: false,
    data: null,
    message: 'Not Loaded',
    error: null,
    status: false,
  },
  addressAndCoordinates: {
    loading: false,
    data: null,
    message: 'Not Loaded',
    error: null,
    status: false,
  },
  subHeader: {
    loading: false,
    data: null,
    message: 'Not Loaded',
    error: null,
    status: false,
  },
  loginSlider: {
    loading: false,
    data: null,
    message: 'Not Loaded',
    error: null,
    status: false,
  },
  userCoupons: {
    loading: false,
    data: null,
    message: 'Not Loaded',
    error: null,
    status: false,
  },
  splashScreen: {
    loading: false,
    data: null,
    message: 'Not Loaded',
    error: null,
    status: false,
  },
};

const pageAtHomeSlice = createSlice({
  name: 'pageAtHome',
  initialState,
  reducers: {
    clearPageBlocks: (state) => pageAtHomeActions.clearPageBlocks(state),
    clearAddressAndCoordinates: (state) => pageAtHomeActions.clearAddressAndCoordinates(state),
    clearSubHeader: (state) => pageAtHomeActions.clearSubHeader(state),
    clearUserCoupons: (state) => pageAtHomeActions.clearUserCoupons(state),
    clearSplashScreen: (state) => pageAtHomeActions.clearSplashScreen(state),
    setSplashScreen: (state) => {
      const splashScreen = {
        loading: false,
        data: 1,
        status: true,
      }
      return { ...state, splashScreen: { ...splashScreen } };
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(pageAtHomeActions.getPageBlocksRequest.pending, (state, action) =>
        pageAtHomeReducerData.requestDataForPageBlockData(state, action, 'pending'),
      )
      .addCase(pageAtHomeActions.getPageBlocksRequest.fulfilled, (state, action) =>
        pageAtHomeReducerData.requestDataForPageBlockData(state, action, 'fulfilled'),
      )
      .addCase(pageAtHomeActions.getPageBlocksRequest.rejected, (state, action) =>
        pageAtHomeReducerData.requestDataForPageBlockData(state, action, 'rejected'),
      );

    builder
      .addCase(pageAtHomeActions.getAddressAndCoordinatesFromPlaceIdRequest.pending, (state, action) =>
        pageAtHomeReducerData.requestDataForAddressAndCoordinaes(state, action, 'pending'),
      )
      .addCase(pageAtHomeActions.getAddressAndCoordinatesFromPlaceIdRequest.fulfilled, (state, action) =>
        pageAtHomeReducerData.requestDataForAddressAndCoordinaes(state, action, 'fulfilled'),
      )
      .addCase(pageAtHomeActions.getAddressAndCoordinatesFromPlaceIdRequest.rejected, (state, action) =>
        pageAtHomeReducerData.requestDataForAddressAndCoordinaes(state, action, 'rejected'),
      );

    builder
      .addCase(pageAtHomeActions.getHomePageSubHeadRequest.pending, (state, action) =>
        pageAtHomeReducerData.requestDataForSubHeader(state, action, 'pending'),
      )
      .addCase(pageAtHomeActions.getHomePageSubHeadRequest.fulfilled, (state, action) =>
        pageAtHomeReducerData.requestDataForSubHeader(state, action, 'fulfilled'),
      )
      .addCase(pageAtHomeActions.getHomePageSubHeadRequest.rejected, (state, action) =>
        pageAtHomeReducerData.requestDataForSubHeader(state, action, 'rejected'),
      );

    builder
      .addCase(pageAtHomeActions.getLoginSliderRequest.pending, (state, action) =>
        pageAtHomeReducerData.requestDataForLoginSlider(state, action, 'pending'),
      )
      .addCase(pageAtHomeActions.getLoginSliderRequest.fulfilled, (state, action) =>
        pageAtHomeReducerData.requestDataForLoginSlider(state, action, 'fulfilled'),
      )
      .addCase(pageAtHomeActions.getLoginSliderRequest.rejected, (state, action) =>
        pageAtHomeReducerData.requestDataForLoginSlider(state, action, 'rejected'),
      );

    builder
      .addCase(pageAtHomeActions.getUserCouponsRequest.pending, (state, action) =>
        pageAtHomeReducerData.requestDataForUserCoupon(state, action, 'pending'),
      )
      .addCase(pageAtHomeActions.getUserCouponsRequest.fulfilled, (state, action) =>
        pageAtHomeReducerData.requestDataForUserCoupon(state, action, 'fulfilled'),
      )
      .addCase(pageAtHomeActions.getUserCouponsRequest.rejected, (state, action) =>
        pageAtHomeReducerData.requestDataForUserCoupon(state, action, 'rejected'),
      );
  },
});

export const {
  clearPageBlocks,
  clearAddressAndCoordinates,
  clearSubHeader,
  clearUserCoupons,
  setSplashScreen,
  clearSplashScreen,
} = pageAtHomeSlice.actions;

export default pageAtHomeSlice.reducer;
