import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { WEBSITE_URL } from '../../configs/website';

const initialState = {
  allData: {
    loading: false,
    data: null,
    message: 'Not Loaded',
    error: null,
    status: false,
  },
  singleData: {
    loading: false,
    data: null,
    message: 'Not Loaded',
    error: null,
    status: false,
  },
  saveRecipe: {
    loading: false,
    data: null,
    message: 'Not Loaded',
    error: null,
    status: false,
  },
  savedRecipe: {
    loading: false,
    data: null,
    message: 'Not Loaded',
    error: null,
    status: false,
  },
  recommendedRecipe: {
    loading: false,
    data: null,
    message: 'Not Loaded',
    error: null,
    status: false,
  },
  recipeHeaders: {
    loading: false,
    data: null,
    message: 'Not Loaded',
    error: null,
    status: false,
  },
  recipeBanners: {
    loading: false,
    data: null,
    message: 'Not Loaded',
    error: null,
    status: false,
  },
  recipeHeaderItems: {
    loading: false,
    data: null,
    message: 'Not Loaded',
    error: null,
    status: false,
  },
  recipeBannerItems: {
    loading: false,
    data: null,
    message: 'Not Loaded',
    error: null,
    status: false,
  },
  recipeDishes: {
    loading: false,
    data: null,
    message: 'Not Loaded',
    error: null,
    status: false,
  },
  recipeDishItems: {
    loading: false,
    data: null,
    message: 'Not Loaded',
    error: null,
    status: false,
  },
  viewedRecipe: {
    loading: false,
    data: null,
    message: 'Not Loaded',
    error: null,
    status: false,
  },
  trendingRecipe: {
    loading: false,
    data: null,
    message: 'Not Loaded',
    error: null,
    status: false,
  },
  popularRecipie: {
    loading: false,
    data: null,
    message: 'Not Loaded',
    error: null,
    status: false,
  },
  recipeCategories: {
    loading: false,
    data: null,
    message: 'Not Loaded',
    error: null,
    status: false,
  },
};

export const getAllRecipeRequest = createAsyncThunk('recipes/get-all-recipe', async ({ page, searchTerm }) => {
  try {
    let searchQuery = '';
    if (searchTerm !== undefined && `${searchTerm}`.length > 0) {
      searchQuery = `&searchTerm=${searchTerm}`;
    }
    const allData = await axios.post(`${WEBSITE_URL}/public/api/get-recipes-all?page=${page}${searchQuery}`);
    return { ...allData.data };
  } catch (error) {
    return {
      loading: false,
      data: null,
      message: `${error}`,
      error,
      status: false,
    };
  }
});

export const getSingleRecipeRequest = createAsyncThunk('recipes/get-single-recipe', async ({ id, token }) => {
  try {
    const singleData = await axios
      .post(`${WEBSITE_URL}/public/api/${token ? `get-single-recipe-user` : `get-single-recipe`}`, { id, token });
    return { ...singleData.data };
  } catch (error) {
    return {
      loading: false,
      data: null,
      message: `${error}`,
      error,
      status: false,
    };
  }
});

export const saveRecipe = createAsyncThunk('recipes/save-customer-recipe', async ({ id, isSave, token }) => {
  try {
    const saveRecipe = await axios.post(`${WEBSITE_URL}/public/api/save-recipe`, { id, isSave, token });
    return { ...saveRecipe.data };
  } catch (error) {
    return {
      loading: false,
      data: null,
      message: `${error}`,
      error,
      status: false,
    };
  }
});

export const getSavedRecipeRequest = createAsyncThunk('recipes/customer-saved-recipe', async ({ token }) => {
  try {
    const savedRecipes = await axios.post(`${WEBSITE_URL}/public/api/get-saved-recipies`, { token });
    return { ...savedRecipes.data };
  } catch (error) {
    return {
      loading: false,
      data: null,
      message: `${error}`,
      error,
      status: false,
    };
  }
});

export const setViewRecipeRequest = createAsyncThunk('recipes/customer-view-recipe', async ({ token, id }) => {
  try {
    const savedRecipes = await axios.post(`${WEBSITE_URL}/public/api/save-view-recipe`, { token, id });
    return { ...savedRecipes.data };
  } catch (error) {
    return {
      loading: false,
      data: null,
      message: `${error}`,
      error,
      status: false,
    };
  }
});

export const getViewRecipeRequest = createAsyncThunk('recipes/get-customer-view-recipe', async ({ token }) => {
  try {
    const viewedRecipe = await axios.post(`${WEBSITE_URL}/public/api/get-viewed-recipies`, { token });
    return { ...viewedRecipe.data };
  } catch (error) {
    return {
      loading: false,
      data: null,
      message: `${error}`,
      error,
      status: false,
    };
  }
});

export const getTrendingRecipeRequest = createAsyncThunk('recipes/get-trending-recipe', async (data) => {
  try {
    const trendingRecipe = await axios.post(`${WEBSITE_URL}/public/api/${data.token ? `get-trending-recipies-logged` : `get-trending-recipies`}`, data);
    return { ...trendingRecipe.data };
  } catch (error) {
    return {
      loading: false,
      data: null,
      message: `${error}`,
      error,
      status: false,
    };
  }
});

export const getPopularRecipeRequest = createAsyncThunk('recipes/get-popular-recipe', async () => {
  try {
    const popularRecipie = await axios.post(`${WEBSITE_URL}/public/api/get-popular-recipies`, {});
    return { ...popularRecipie.data };
  } catch (error) {
    return {
      loading: false,
      data: null,
      message: `${error}`,
      error,
      status: false,
    };
  }
});

export const getRecommondedRecipeRequest = createAsyncThunk('recipes/recommonded-recipe', async () => {
  try {
    const recommendedRecipe = await axios.post(`${WEBSITE_URL}/public/api/get-recommonded-recipies`);
    return { ...recommendedRecipe.data };
  } catch (error) {
    return {
      loading: false,
      data: null,
      message: `${error}`,
      error,
      status: false,
    };
  }
});

export const getRecipeHeaderRequest = createAsyncThunk('recipes/get-recipe-header-request',
  async ({ id }) => {
    try {
      const recipeHeaders = await axios.post(`${WEBSITE_URL}/public/api/get-recipe-headers`, { id });
      return { ...recipeHeaders.data };
    } catch (error) {
      return {
        loading: false,
        data: null,
        message: `${error}`,
        error,
        status: false,
      };
    }
  },
);

export const getRecipeBannerRequest = createAsyncThunk('recipes/get-recipe-banner-request', async ({ id }) => {
  try {
    const recipeBanners = await axios.post(`${WEBSITE_URL}/public/api/get-recipe-banners`, { id });
    return { ...recipeBanners.data };
  } catch (error) {
    return {
      loading: false,
      data: null,
      message: `${error}`,
      error,
      status: false,
    };
  }
});

export const getRecipeHeaderElementsRequest = createAsyncThunk('recipes/get-recipe-header-elements-request',
  async ({ page, searchTerm, id }) => {
    try {
      let searchQuery = '';
      if (searchTerm !== undefined && `${searchTerm}`.length > 0) {
        searchQuery = `&searchTerm=${searchTerm}`;
      }
      const recipeHeaderItems = await axios.post(`${WEBSITE_URL}/public/api/get-recipe-header-elements?page=${page}${searchQuery}`, { id });
      return { ...recipeHeaderItems.data };
    } catch (error) {
      return {
        loading: false,
        data: null,
        message: `${error}`,
        error,
        status: false,
      };
    }
  },
);

export const getRecipeBannerElementsRequest = createAsyncThunk('recipes/get-recipe-banner-elements-request',
  async ({ page, searchTerm, id }) => {
    try {
      let searchQuery = '';
      if (searchTerm !== undefined && `${searchTerm}`.length > 0) {
        searchQuery = `&searchTerm=${searchTerm}`;
      }
      const recipeBannerItems = await axios.post(`${WEBSITE_URL}/public/api/get-recipe-banner-elements?page=${page}${searchQuery}`, { id });
      return { ...recipeBannerItems.data };
    } catch (error) {
      return {
        loading: false,
        data: null,
        message: `${error}`,
        error,
        status: false,
      };
    }
  },
);

export const getRecipeDishRequest = createAsyncThunk('recipes/get-recipe-dishes',
  async ({ id }) => {
    try {
      const recipeDishes = await axios.post(`${WEBSITE_URL}/public/api/get-recipe-dishes`, { id });
      return { ...recipeDishes.data };
    } catch (error) {
      return {
        loading: false,
        data: null,
        message: `${error}`,
        error,
        status: false,
      };
    }
  },
);

export const getRecipeDishItemsRequest = createAsyncThunk('recipes/get-recipe-dish-items',
  async ({ page, searchTerm, id }) => {
    try {
      let searchQuery = '';
      if (searchTerm !== undefined && `${searchTerm}`.length > 0) {
        searchQuery = `&searchTerm=${searchTerm}`;
      }
      const recipeDishItems = await axios.post(`${WEBSITE_URL}/public/api/get-recipe-dish-items?page=${page}${searchQuery}`, { id });
      return { ...recipeDishItems.data };
    } catch (error) {
      return {
        loading: false,
        data: null,
        message: `${error}`,
        error,
        status: false,
      };
    }
  },
);

const recipeSlice = createSlice({
  name: 'recipes',
  initialState,
  reducers: {
    clearAllRecipeData: (state) => {
      const allData = {
        loading: false,
        data: null,
        message: 'Not Loaded',
        error: null,
        status: false,
      };
      state = { ...state, allData };
      return state;
    },
    clearSaveRecipe: (state) => {
      const saveRecipe = {
        loading: false,
        data: null,
        message: 'Not Loaded',
        error: null,
        status: false,
      };
      state = { ...state, saveRecipe };
      return state;
    },
    clearSavedRecipe: (state) => {
      const savedRecipe = {
        loading: false,
        data: null,
        message: 'Not Loaded',
        error: null,
        status: false,
      };
      state = { ...state, savedRecipe };
      return state;
    },
    clearRecommondedRecipe: (state) => {
      const recommendedRecipe = {
        loading: false,
        data: null,
        message: 'Not Loaded',
        error: null,
        status: false,
      };
      state = { ...state, recommendedRecipe };
      return state;
    },
    clearViewedRecipe: (state) => {
      const viewedRecipe = {
        loading: false,
        data: null,
        message: 'Not Loaded',
        error: null,
        status: false,
      };
      state = { ...state, viewedRecipe };
      return state;
    },
    clearTrendingRecipe: (state) => {
      const trendingRecipe = {
        loading: false,
        data: null,
        message: 'Not Loaded',
        error: null,
        status: false,
      };
      state = { ...state, trendingRecipe };
      return state;
    },
    clearPopularRecipe: (state) => {
      const popularRecipie = {
        loading: false,
        data: null,
        message: 'Not Loaded',
        error: null,
        status: false,
      };
      state = { ...state, popularRecipie };
      return state;
    },
    clearRecipeHeader: (state) => {
      const recipeHeaders = {
        loading: false,
        data: null,
        message: 'Not Loaded',
        error: null,
        status: false,
      };
      state = { ...state, recipeHeaders };
      return state;
    },
    clearRecipeBanner: (state) => {
      const recipeBanners = {
        loading: false,
        data: null,
        message: 'Not Loaded',
        error: null,
        status: false,
      };
      state = { ...state, recipeBanners };
      return state;
    },
    clearRecipeHeaderItems: (state) => {
      const recipeHeaderItems = {
        loading: false,
        data: null,
        message: 'Not Loaded',
        error: null,
        status: false,
      };
      state = { ...state, recipeHeaderItems };
      return state;
    },
    clearRecipeBannerItems: (state) => {
      const recipeBannerItems = {
        loading: false,
        data: null,
        message: 'Not Loaded',
        error: null,
        status: false,
      };
      state = { ...state, recipeBannerItems };
      return state;
    },
    clearRecipeDishes: (state) => {
      const recipeDishes = {
        loading: false,
        data: null,
        message: 'Not Loaded',
        error: null,
        status: false,
      };
      state = { ...state, recipeDishes };
      return state;
    },
    clearRecipeDishItems: (state) => {
      const recipeDishItems = {
        loading: false,
        data: null,
        message: 'Not Loaded',
        error: null,
        status: false,
      };
      state = { ...state, recipeDishItems };
      return state;
    },
    clearRecipeCategories: (state) => {
      const recipeCategories = {
        loading: false,
        data: null,
        message: 'Not Loaded',
        error: null,
        status: false,
      };
      state = { ...state, recipeCategories };
      return state;
    },
    setRecipeCategories: (state, action) => {
      const recipeCategories = {
        loading: false,
        data: action.payload.data,
        message: 'Loaded',
        error: null,
        status: true,
      };
      state = { ...state, recipeCategories };
      return state;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllRecipeRequest.pending, (state) => {
        const allData = { loading: true, data: null, message: 'Not Loaded', error: null, status: true };
        state = { ...state };
        state.allData = { ...allData };
        return state;
      })
      .addCase(getAllRecipeRequest.fulfilled, (state, action) => {
        const allData = { ...state.tableData };
        allData.loading = false;
        try {
          allData.data = action.payload.data;
          allData.message = action.payload.message;
          allData.status = action.payload.status;
          allData.error = null;
        } catch (error) {
          allData.error = error;
        }
        state = { ...state };
        state.allData = { ...allData };
        return state;
      })
      .addCase(getAllRecipeRequest.rejected, (state, action) => {
        const allData = { ...state.allData };
        allData.loading = false;
        allData.status = false;
        allData.error = action.error.message;
        state = { ...state };
        state.allData = { ...allData };
        return state;
      });

    builder
      .addCase(getSingleRecipeRequest.pending, (state) => {
        const singleData = { loading: true, data: null, message: 'Not Loaded', error: null, status: true };
        state = { ...state };
        state.singleData = { ...singleData };
        return state;
      })
      .addCase(getSingleRecipeRequest.fulfilled, (state, action) => {
        const singleData = { ...state.tableData };
        singleData.loading = false;
        try {
          singleData.data = action.payload.data;
          singleData.message = action.payload.message;
          singleData.status = action.payload.status;
          singleData.error = null;
        } catch (error) {
          singleData.error = error;
        }
        state = { ...state };
        state.singleData = { ...singleData };
        return state;
      })
      .addCase(getSingleRecipeRequest.rejected, (state, action) => {
        const singleData = { ...state.singleData };
        singleData.loading = false;
        singleData.status = false;
        singleData.error = action.error.message;
        singleData.data = action.error.message;
        state = { ...state };
        state.singleData = { ...singleData };
        return state;
      });

    builder
      .addCase(saveRecipe.pending, (state) => {
        const saveRecipe = { loading: true, data: null, message: 'Not Loaded', error: null, status: true };
        state = { ...state };
        state.saveRecipe = { ...saveRecipe };
        return state;
      })
      .addCase(saveRecipe.fulfilled, (state, action) => {
        const saveRecipe = { ...state.saveRecipe };
        saveRecipe.loading = false;
        try {
          saveRecipe.data = action.payload.data;
          saveRecipe.message = action.payload.message;
          saveRecipe.status = action.payload.status;
          saveRecipe.error = null;
        } catch (error) {
          saveRecipe.error = error;
        }
        state = { ...state };
        state.saveRecipe = { ...saveRecipe };
        return state;
      })
      .addCase(saveRecipe.rejected, (state, action) => {
        const saveRecipe = { ...state.saveRecipe };
        saveRecipe.loading = false;
        saveRecipe.status = false;
        saveRecipe.error = action.error.message;
        saveRecipe.data = action.error.message;
        state = { ...state };
        state.saveRecipe = { ...saveRecipe };
        return state;
      });

    builder
      .addCase(getSavedRecipeRequest.pending, (state) => {
        const savedRecipe = { loading: true, data: null, message: 'Not Loaded', error: null, status: true };
        state = { ...state };
        state.savedRecipe = { ...savedRecipe };
        return state;
      })
      .addCase(getSavedRecipeRequest.fulfilled, (state, action) => {
        const savedRecipe = { ...state.savedRecipe };
        savedRecipe.loading = false;
        try {
          savedRecipe.data = action.payload.data;
          savedRecipe.message = action.payload.message;
          savedRecipe.status = action.payload.status;
          savedRecipe.error = null;
        } catch (error) {
          savedRecipe.error = error;
        }
        state = { ...state };
        state.savedRecipe = { ...savedRecipe };
        return state;
      })
      .addCase(getSavedRecipeRequest.rejected, (state, action) => {
        const savedRecipe = { ...state.savedRecipe };
        savedRecipe.loading = false;
        savedRecipe.status = false;
        savedRecipe.error = action.error.message;
        savedRecipe.data = action.error.message;
        state = { ...state };
        state.savedRecipe = { ...savedRecipe };
        return state;
      });

    builder
      .addCase(getRecommondedRecipeRequest.pending, (state) => {
        const recommendedRecipe = { loading: true, data: null, message: 'Not Loaded', error: null, status: true };
        state = { ...state };
        state.recommendedRecipe = { ...recommendedRecipe };
        return state;
      })
      .addCase(getRecommondedRecipeRequest.fulfilled, (state, action) => {
        const recommendedRecipe = { ...state.recommendedRecipe };
        recommendedRecipe.loading = false;
        try {
          recommendedRecipe.data = action.payload.data;
          recommendedRecipe.message = action.payload.message;
          recommendedRecipe.status = action.payload.status;
          recommendedRecipe.error = null;
        } catch (error) {
          recommendedRecipe.error = error;
        }
        state = { ...state };
        state.recommendedRecipe = { ...recommendedRecipe };
        return state;
      })
      .addCase(getRecommondedRecipeRequest.rejected, (state, action) => {
        const recommendedRecipe = { ...state.recommendedRecipe };
        recommendedRecipe.loading = false;
        recommendedRecipe.status = false;
        recommendedRecipe.error = action.error.message;
        recommendedRecipe.data = action.error.message;
        state = { ...state };
        state.recommendedRecipe = { ...recommendedRecipe };
        return state;
      });

    builder
      .addCase(getViewRecipeRequest.pending, (state) => {
        const viewedRecipe = { loading: true, data: null, message: 'Not Loaded', error: null, status: true };
        state = { ...state };
        state.viewedRecipe = { ...viewedRecipe };
        return state;
      })
      .addCase(getViewRecipeRequest.fulfilled, (state, action) => {
        const viewedRecipe = { ...state.viewedRecipe };
        viewedRecipe.loading = false;
        try {
          viewedRecipe.data = action.payload.data;
          viewedRecipe.message = action.payload.message;
          viewedRecipe.status = action.payload.status;
          viewedRecipe.error = null;
        } catch (error) {
          viewedRecipe.error = error;
        }
        state = { ...state };
        state.viewedRecipe = { ...viewedRecipe };
        return state;
      })
      .addCase(getViewRecipeRequest.rejected, (state, action) => {
        const viewedRecipe = { ...state.viewedRecipe };
        viewedRecipe.loading = false;
        viewedRecipe.status = false;
        viewedRecipe.error = action.error.message;
        viewedRecipe.data = action.error.message;
        state = { ...state };
        state.viewedRecipe = { ...viewedRecipe };
        return state;
      });

    builder
      .addCase(getTrendingRecipeRequest.pending, (state) => {
        const trendingRecipe = { loading: true, data: null, message: 'Not Loaded', error: null, status: true };
        state = { ...state };
        state.trendingRecipe = { ...trendingRecipe };
        return state;
      })
      .addCase(getTrendingRecipeRequest.fulfilled, (state, action) => {
        const trendingRecipe = { ...state.trendingRecipe };
        trendingRecipe.loading = false;
        try {
          trendingRecipe.data = action.payload.data;
          trendingRecipe.message = action.payload.message;
          trendingRecipe.status = action.payload.status;
          trendingRecipe.error = null;
        } catch (error) {
          trendingRecipe.error = error;
        }
        state = { ...state };
        state.trendingRecipe = { ...trendingRecipe };
        return state;
      })
      .addCase(getTrendingRecipeRequest.rejected, (state, action) => {
        const trendingRecipe = { ...state.trendingRecipe };
        trendingRecipe.loading = false;
        trendingRecipe.status = false;
        trendingRecipe.error = action.error.message;
        trendingRecipe.data = action.error.message;
        state = { ...state };
        state.trendingRecipe = { ...trendingRecipe };
        return state;
      });

    builder
      .addCase(getPopularRecipeRequest.pending, (state) => {
        const popularRecipie = { loading: true, data: null, message: 'Not Loaded', error: null, status: true };
        state = { ...state };
        state.popularRecipie = { ...popularRecipie };
        return state;
      })
      .addCase(getPopularRecipeRequest.fulfilled, (state, action) => {
        const popularRecipie = { ...state.popularRecipie };
        popularRecipie.loading = false;
        try {
          popularRecipie.data = action.payload.data;
          popularRecipie.message = action.payload.message;
          popularRecipie.status = action.payload.status;
          popularRecipie.error = null;
        } catch (error) {
          popularRecipie.error = error;
        }
        state = { ...state };
        state.popularRecipie = { ...popularRecipie };
        return state;
      })
      .addCase(getPopularRecipeRequest.rejected, (state, action) => {
        const popularRecipie = { ...state.popularRecipie };
        popularRecipie.loading = false;
        popularRecipie.status = false;
        popularRecipie.error = action.error.message;
        popularRecipie.data = action.error.message;
        state = { ...state };
        state.popularRecipie = { ...popularRecipie };
        return state;
      });

    builder
      .addCase(getRecipeHeaderRequest.pending, (state) => {
        const recipeHeaders = { loading: true, data: null, message: 'Not Loaded', error: null, status: true };
        state = { ...state };
        state.recipeHeaders = { ...recipeHeaders };
        return state;
      })
      .addCase(getRecipeHeaderRequest.fulfilled, (state, action) => {
        const recipeHeaders = { ...state.recipeHeaders };
        recipeHeaders.loading = false;
        try {
          recipeHeaders.data = action.payload.data;
          recipeHeaders.message = action.payload.message;
          recipeHeaders.status = action.payload.status;
          recipeHeaders.error = null;
        } catch (error) {
          recipeHeaders.error = error;
        }
        state = { ...state };
        state.recipeHeaders = { ...recipeHeaders };
        return state;
      })
      .addCase(getRecipeHeaderRequest.rejected, (state, action) => {
        const recipeHeaders = { ...state.recipeHeaders };
        recipeHeaders.loading = false;
        recipeHeaders.status = false;
        recipeHeaders.error = action.error.message;
        recipeHeaders.data = action.error.message;
        state = { ...state };
        state.recipeHeaders = { ...recipeHeaders };
        return state;
      });

    builder
      .addCase(getRecipeBannerRequest.pending, (state) => {
        const recipeBanners = { loading: true, data: null, message: 'Not Loaded', error: null, status: true };
        state = { ...state };
        state.recipeBanners = { ...recipeBanners };
        return state;
      })
      .addCase(getRecipeBannerRequest.fulfilled, (state, action) => {
        const recipeBanners = { ...state.recipeBanners };
        recipeBanners.loading = false;
        try {
          recipeBanners.data = action.payload.data;
          recipeBanners.message = action.payload.message;
          recipeBanners.status = action.payload.status;
          recipeBanners.error = null;
        } catch (error) {
          recipeBanners.error = error;
        }
        state = { ...state };
        state.recipeBanners = { ...recipeBanners };
        return state;
      })
      .addCase(getRecipeBannerRequest.rejected, (state, action) => {
        const recipeBanners = { ...state.recipeBanners };
        recipeBanners.loading = false;
        recipeBanners.status = false;
        recipeBanners.error = action.error.message;
        recipeBanners.data = action.error.message;
        state = { ...state };
        state.recipeBanners = { ...recipeBanners };
        return state;
      });

    builder
      .addCase(getRecipeHeaderElementsRequest.pending, (state) => {
        const recipeHeaderItems = { loading: true, data: null, message: 'Not Loaded', error: null, status: true };
        state = { ...state };
        state.recipeHeaderItems = { ...recipeHeaderItems };
        return state;
      })
      .addCase(getRecipeHeaderElementsRequest.fulfilled, (state, action) => {
        const recipeHeaderItems = { ...state.recipeHeaderItems };
        recipeHeaderItems.loading = false;
        try {
          recipeHeaderItems.data = action.payload.data;
          recipeHeaderItems.message = action.payload.message;
          recipeHeaderItems.status = action.payload.status;
          recipeHeaderItems.error = null;
        } catch (error) {
          recipeHeaderItems.error = error;
        }
        state = { ...state };
        state.recipeHeaderItems = { ...recipeHeaderItems };
        return state;
      })
      .addCase(getRecipeHeaderElementsRequest.rejected, (state, action) => {
        const recipeHeaderItems = { ...state.recipeHeaderItems };
        recipeHeaderItems.loading = false;
        recipeHeaderItems.status = false;
        recipeHeaderItems.error = action.error.message;
        recipeHeaderItems.data = action.error.message;
        state = { ...state };
        state.recipeHeaderItems = { ...recipeHeaderItems };
        return state;
      });

    builder
      .addCase(getRecipeBannerElementsRequest.pending, (state) => {
        const recipeBannerItems = { loading: true, data: null, message: 'Not Loaded', error: null, status: true };
        state = { ...state };
        state.recipeHeaderItems = { ...recipeBannerItems };
        return state;
      })
      .addCase(getRecipeBannerElementsRequest.fulfilled, (state, action) => {
        const recipeBannerItems = { ...state.recipeBannerItems };
        recipeBannerItems.loading = false;
        try {
          recipeBannerItems.data = action.payload.data;
          recipeBannerItems.message = action.payload.message;
          recipeBannerItems.status = action.payload.status;
          recipeBannerItems.error = null;
        } catch (error) {
          recipeBannerItems.error = error;
        }
        state = { ...state };
        state.recipeBannerItems = { ...recipeBannerItems };
        return state;
      })
      .addCase(getRecipeBannerElementsRequest.rejected, (state, action) => {
        const recipeBannerItems = { ...state.recipeBannerItems };
        recipeBannerItems.loading = false;
        recipeBannerItems.status = false;
        recipeBannerItems.error = action.error.message;
        recipeBannerItems.data = action.error.message;
        state = { ...state };
        state.recipeBannerItems = { ...recipeBannerItems };
        return state;
      });

    builder
      .addCase(getRecipeDishRequest.pending, (state) => {
        const recipeDishes = { loading: true, data: null, message: 'Not Loaded', error: null, status: true };
        state = { ...state };
        state.recipeDishes = { ...recipeDishes };
        return state;
      })
      .addCase(getRecipeDishRequest.fulfilled, (state, action) => {
        const recipeDishes = { ...state.recipeDishes };
        recipeDishes.loading = false;
        try {
          recipeDishes.data = action.payload.data;
          recipeDishes.message = action.payload.message;
          recipeDishes.status = action.payload.status;
          recipeDishes.error = null;
        } catch (error) {
          recipeDishes.error = error;
        }
        state = { ...state };
        state.recipeDishes = { ...recipeDishes };
        return state;
      })
      .addCase(getRecipeDishRequest.rejected, (state, action) => {
        const recipeDishes = { ...state.recipeDishes };
        recipeDishes.loading = false;
        recipeDishes.status = false;
        recipeDishes.error = action.error.message;
        recipeDishes.data = action.error.message;
        state = { ...state };
        state.recipeDishes = { ...recipeDishes };
        return state;
      });

    builder
      .addCase(getRecipeDishItemsRequest.pending, (state) => {
        const recipeDishItems = { loading: true, data: null, message: 'Not Loaded', error: null, status: true };
        state = { ...state };
        state.recipeDishItems = { ...recipeDishItems };
        return state;
      })
      .addCase(getRecipeDishItemsRequest.fulfilled, (state, action) => {
        const recipeDishItems = { ...state.recipeDishItems };
        recipeDishItems.loading = false;
        try {
          recipeDishItems.data = action.payload.data;
          recipeDishItems.message = action.payload.message;
          recipeDishItems.status = action.payload.status;
          recipeDishItems.error = null;
        } catch (error) {
          recipeDishItems.error = error;
        }
        state = { ...state };
        state.recipeDishItems = { ...recipeDishItems };
        return state;
      })
      .addCase(getRecipeDishItemsRequest.rejected, (state, action) => {
        const recipeDishItems = { ...state.recipeDishItems };
        recipeDishItems.loading = false;
        recipeDishItems.status = false;
        recipeDishItems.error = action.error.message;
        recipeDishItems.data = action.error.message;
        state = { ...state };
        state.recipeDishItems = { ...recipeDishItems };
        return state;
      });
  },
});

export const getRecipeAllData = (state) => state?.recipes?.allData;
export const getRecipeSingleData = (state) => state?.recipes?.singleData;
export const getRecipeSavedData = (state) => state?.recipes?.savedRecipe;
export const getRecipeSavingData = (state) => state?.recipes?.saveRecipe;
export const getRecipeRecommondedData = (state) => state?.recipes?.recommendedRecipe;
export const getRecipeViewedData = (state) => state?.recipes?.viewedRecipe;
export const getRecipeTrendingData = (state) => state?.recipes?.trendingRecipe;
export const getRecipePopularData = (state) => state?.recipes?.popularRecipie;
export const getRecipeHeaders = (state) => state?.recipes?.recipeHeaders;
export const getRecipeBanners = (state) => state?.recipes?.recipeBanners;
export const getRecipeHeaderItems = (state) => state?.recipes?.recipeHeaderItems;
export const getRecipeBannerItems = (state) => state?.recipes?.recipeBannerItems;
export const getRecipeDishes = (state) => state?.recipes?.recipeDishes;
export const getRecipeDishItems = (state) => state?.recipes?.recipeDishItems;

export const {
  clearAllRecipeData,
  clearSaveRecipe,
  clearSavedRecipe,
  clearRecommondedRecipe,
  clearTrendingRecipe,
  clearPopularRecipe,
  clearRecipeHeaderItems,
  clearRecipeBannerItems,
  clearRecipeDishes,
  clearRecipeDishItems,
  setRecipeCategories,
  clearRecipeCategories,
} = recipeSlice.actions;

export default recipeSlice.reducer;