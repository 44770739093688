import { GET_BILL_BOARD } from "./actionTypes";

const initialState = {
    bill_board_slides: []
};

export default function(state = initialState, action) {
    switch (action.type) {
        case GET_BILL_BOARD:
            return { ...state, bill_board_slides: action.payload };
        default:
            return state;
    }
}
