import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { APPLY_COUPON_URL } from "../../configs";
import { API_URL } from "../../configs/website";
const initialState = {
  coupon: [],
  coupon_error: "",
};
export const applyCoupon = createAsyncThunk("settings/apply-coupon-request-from-card-click",
  async ({ token, coupon, restaurant_id, subtotal, cartProducts, city, restaurants }) => {
    try {
      const createRequest = await axios.post(APPLY_COUPON_URL, {
        token: token,
        coupon: coupon,
        restaurant_id: restaurant_id,
        subtotal: subtotal,
        cartProducts: cartProducts,
        city: city,
        restaurants: restaurants
      });

      return { coupon: { ...createRequest.data, restaurant_id: restaurant_id }, coupon_error: "" };
    } catch (error) {
      return { coupon_error: "NOTLOGGEDIN" };
    }
  })

export const autoApplyCoupon = createAsyncThunk("settings/get-saved-address-request",
  async ({ token, restaurant_id, subtotal, cartProducts, city, restaurants, resto_props }) => {
    let items = [];
    cartProducts.forEach(item => {
      items.push({ id: item.id })
    });
    try {
      const createRequest = await axios.post(API_URL + '/auto-apply-coupon', {
        token: token,
        restaurant_id: restaurant_id,
        subtotal: subtotal,
        cartProducts: items,
        city: city,
        restaurants: restaurants,
        resto_props: resto_props,
      });

      return { coupon: createRequest.data, coupon_error: "" };
    } catch (error) {
      return { coupon_error: "NOTLOGGEDIN" };
    }
  })

const couponSlice = createSlice({
  name: 'coupon',
  initialState,
  reducers: {
    removeCoupon: (state, _) => {
      localStorage.removeItem("appliedCoupon")
      localStorage.removeItem("couponRestaurant")
      const coupon = { hideMessage: true, coupon_error: "" };
      state.coupon = coupon;
    },
    couponApplied: (state, action) => {
      const { coupon, percentage_discount } = action.payload
      coupon.appliedAmount = percentage_discount;
      state.coupon = coupon;
    }
  },
  extraReducers: {
    [applyCoupon.pending]: (state, _) => {
      state.coupon = {};
    },
    [applyCoupon.fulfilled]: (state, action) => {
      state.coupon = action.payload.coupon;
      state.coupon_error = action.payload.coupon_error;
      // if(action.payload?.coupon?.success) {
      //   localStorage.setItem("couponRestaurant", )
      // }
    },
    [applyCoupon.rejected]: (state, action) => {
      state.coupon = {};
    },
    [autoApplyCoupon.pending]: (state, _) => {
      state.coupon = {};
    },
    [autoApplyCoupon.fulfilled]: (state, action) => {
      state.coupon = action.payload.coupon;
      state.coupon_error = action.payload.coupon_error;
    },
    [autoApplyCoupon.rejected]: (state, action) => {
      state.coupon = {};
    },
  }
})

export const { removeCoupon, couponApplied } = couponSlice.actions

export default couponSlice.reducer;
