import rootReducer from "./reducers";
import { configureStore, createImmutableStateInvariantMiddleware } from "@reduxjs/toolkit";
import storage from 'redux-persist/lib/storage'
import { persistStore, persistReducer } from 'redux-persist'

const persistConfig = {
	key: 'EatikoRoot',
	storage: storage,
	timeout: null,
}

const immutableInvariantMiddleware = createImmutableStateInvariantMiddleware({
	ignoredPaths: ['ignoredPath', 'ignoredNested.one', 'ignoredNested.two'],
})

const persistedReducer = persistReducer(persistConfig, rootReducer)

const store = configureStore({
	reducer: persistedReducer,
	// middleware: [immutableInvariantMiddleware],
	middleware: (getDefaultMiddleware) => getDefaultMiddleware({
		immutableCheck: false,
		serializableCheck: false,
	})
});
const persistor = persistStore(store)
export { store, persistor };