const createRequestDataReducer = (key) => (state, action, type = 'fulfilled') => {
  const newState = { ...state[key], loading: false, error: null };

  if (type === 'fulfilled') {
    newState.data = action.payload.data;
    newState.message = action.payload.message;
    newState.status = action.payload.status;
  } else if (type === 'pending') {
    newState.loading = true;
    newState.message = 'Not Loaded';
    newState.status = true;
  } else {
    newState.status = false;
    newState.error = action?.error?.message;
    newState.data = action?.error?.message;
  }

  return { ...state, [key]: { ...newState } };
};

export const getDineInCountData = (state) => state?.dineIns?.dineInCount;
export const getDineInCategoriesData = (state) => state?.dineIns?.dineInCategory;
export const getDineInFeaturedData = (state) => state?.dineIns?.dineInFeatured;

export const requestDataForDineInCountData = createRequestDataReducer('dineInCount');
export const requestDataForDineInCategoryData = createRequestDataReducer('dineInCategory');
export const requestDataForDineInFeatured = createRequestDataReducer('dineInFeatured');
