export const getDietPlanHomeData = (state) => state?.dietPlan?.dietPlanHome;
export const getDietPlanBannerData = (state) => state?.dietPlan?.dietBanners;
export const getDietCuratedRestaurantData = (state) => state?.dietPlan?.dietCuratedRestaurants;
export const getDietPlanData = (state) => state?.dietPlan?.dietPlan;
export const getDietBannerData = (state) => state?.dietPlan?.dietBanner;
export const getDietSingleRestaurantData = (state) => state?.dietPlan?.singleRestaurant;

export const requestDataForDietPlanHome = (state, action, type = 'fulfilled') => {
  const dietPlanHome = { ...state.dietPlanHome, loading: false, error: null };

  if (type === 'fulfilled') {
    dietPlanHome.data = action.payload.data;
    dietPlanHome.message = action.payload.message;
    dietPlanHome.status = action.payload.status;
  } else if (type === 'pending') {
    dietPlanHome.loading = true;
    dietPlanHome.message = 'Not Loaded';
    dietPlanHome.status = true;
  } else {
    dietPlanHome.status = false;
    dietPlanHome.error = action?.error?.message;
    dietPlanHome.data = action?.error?.message;
  }

  return { ...state, dietPlanHome: { ...dietPlanHome } };
};

export const requestDataForDietBanners = (state, action, type = 'fulfilled') => {
  const dietBanners = { ...state.dietBanners, loading: false, error: null };

  if (type === 'fulfilled') {
    dietBanners.data = action.payload.data;
    dietBanners.message = action.payload.message;
    dietBanners.status = action.payload.status;
  } else if (type === 'pending') {
    dietBanners.loading = true;
    dietBanners.message = 'Not Loaded';
    dietBanners.status = true;
  } else {
    dietBanners.status = false;
    dietBanners.error = action?.error?.message;
    dietBanners.data = action?.error?.message;
  }

  return { ...state, dietBanners: { ...dietBanners } };
};

export const requestDataForDietCuratedRestaurant = (state, action, type = 'fulfilled') => {
  const dietCuratedRestaurants = { ...state.dietCuratedRestaurants, loading: false, error: null };

  if (type === 'fulfilled') {
    dietCuratedRestaurants.data = action.payload.data;
    dietCuratedRestaurants.message = action.payload.message;
    dietCuratedRestaurants.status = action.payload.status;
  } else if (type === 'pending') {
    dietCuratedRestaurants.loading = true;
    dietCuratedRestaurants.message = 'Not Loaded';
    dietCuratedRestaurants.status = true;
  } else {
    dietCuratedRestaurants.status = false;
    dietCuratedRestaurants.error = action?.error?.message;
    dietCuratedRestaurants.data = action?.error?.message;
  }

  return { ...state, dietCuratedRestaurants: { ...dietCuratedRestaurants } };
};

export const requestDataForDietPlan = (state, action, type = 'fulfilled') => {
  const dietPlan = { ...state.dietPlan, loading: false, error: null };

  if (type === 'fulfilled') {
    dietPlan.data = action.payload.data;
    dietPlan.message = action.payload.message;
    dietPlan.status = action.payload.status;
  } else if (type === 'pending') {
    dietPlan.loading = true;
    dietPlan.message = 'Not Loaded';
    dietPlan.status = true;
  } else {
    dietPlan.status = false;
    dietPlan.error = action?.error?.message;
    dietPlan.data = action?.error?.message;
  }

  return { ...state, dietPlan: { ...dietPlan } };
};

export const requestDataForDietBanner = (state, action, type = 'fulfilled') => {
  const dietBanner = { ...state.dietBanner, loading: false, error: null };

  if (type === 'fulfilled') {
    dietBanner.data = action.payload.data;
    dietBanner.message = action.payload.message;
    dietBanner.status = action.payload.status;
  } else if (type === 'pending') {
    dietBanner.loading = true;
    dietBanner.message = 'Not Loaded';
    dietBanner.status = true;
  } else {
    dietBanner.status = false;
    dietBanner.error = action?.error?.message;
    dietBanner.data = action?.error?.message;
  }

  return { ...state, dietBanner: { ...dietBanner } };
};

export const requestDataForDietSingleRestaurant = (state, action, type = 'fulfilled') => {
  const singleRestaurant = { ...state.singleRestaurant, loading: false, error: null };

  singleRestaurant. loading = false;
  if (type === 'fulfilled') {
    singleRestaurant.data = action.payload.data;
    singleRestaurant.message = action.payload.message;
    singleRestaurant.status = action.payload.status;
  } else if (type === 'pending') {
    singleRestaurant.loading = true;
    singleRestaurant.message = 'Not Loaded';
    singleRestaurant.status = true;
  } else {
    singleRestaurant.status = false;
    singleRestaurant.error = action?.error?.message;
    singleRestaurant.data = action?.error?.message;
  }

  return { ...state, singleRestaurant: { ...singleRestaurant } };
};
