import Loadable from "react-loadable";
import WithRouterPass from './WithRouterPass';
import "./assets/css/grid.css";
import Loading from "./components/helpers/loading";

export const MainApp = Loadable({
  loader: () => import("./components/App"),
  loading: () => <Loading />,
  render: (loaded, props) => {
    return <WithRouterPass {...props} component={loaded.default} />;
  },
});

export const HomePage = Loadable({
  loader: () => import("./components/Mobile/CommonHome/HomePage"),
  loading: () => <Loading />,
  render: (loaded, props) => {
    return <WithRouterPass {...props} component={loaded.default} />;
  },
});

export const FoodDelivery = Loadable({
  loader: () => import("./components/Mobile/CommonHome/EatikoDelivery"),
  loading: () => <Loading />,
  render: (loaded, props) => {
    return <WithRouterPass {...props} component={loaded.default} />;
  },
});

export const OurOffers = Loadable({
  loader: () => import("./components/Mobile/OurOffer"),
  loading: () => <Loading />,
  render: (loaded, props) => {
    return <WithRouterPass
      {...props}
      is_float_cart={true}
      main_page={true}
      is_club_buy={true}
      amount_offer_items={true}
      quick_bites={true}
      footer_show={true}
      coupon_restaurants={true}
      component={loaded.default}
    />;
  },
});

export const LoginOtp = Loadable({
  loader: () => import("./components/Mobile/FirstScreen/Login"),
  loading: () => <Loading />,
  render: (loaded, props) => {
    return <WithRouterPass {...props} component={loaded.default} />;
  },
});

export const Explore = Loadable({
  loader: () => import("./components/Mobile/Explore"),
  loading: () => <Loading />,
  render: (loaded, props) => {
    return <WithRouterPass {...props} component={loaded.default} />;
  },
});

export const ExploreMore = Loadable({
  loader: () => import("./components/Mobile/Explore/ExploreMore"),
  loading: () => <Loading />,
  render: (loaded, props) => {
    return <WithRouterPass {...props} component={loaded.default} />;
  },
});

export const SingleRestaurant = Loadable({
  loader: () => import("./components/Mobile/Items"),
  loading: () => <Loading />,
  render: (loaded, props) => {
    return <WithRouterPass {...props} component={loaded.default} />;
  },
});

export const CartPage = Loadable({
  loader: () => import("./components/Mobile/Cart"),
  loading: () => <Loading />,
  render: (loaded, props) => {
    return <WithRouterPass {...props} component={loaded.default} />;
  },
});

export const Addresses = Loadable({
  loader: () => import("./components/Mobile/Account/Addresses"),
  loading: () => <Loading />,
  render: (loaded, props) => {
    if (props) {
      return <WithRouterPass {...props} component={loaded.default} />;
    } else {
      return <WithRouterPass component={loaded.default} />;
    }
  },
});

export const Location = Loadable({
  loader: () => import("./components/Mobile/Location"),
  loading: () => <Loading />,
  render: (loaded, props) => {
    if (props) {
      return <WithRouterPass {...props} component={loaded.default} />;
    } else {
      return <WithRouterPass component={loaded.default} />;
    }
  },
});

export const GeoLocationPage = Loadable({
  loader: () => import("./components/Mobile/GeoLocationPage"),
  loading: () => <Loading />,
  render: (loaded, props) => {
    if (props) {
      return <WithRouterPass {...props} component={loaded.default} />;
    } else {
      return <WithRouterPass component={loaded.default} />;
    }
  },
});

export const FreshListItems = Loadable(
  {
    loader: () => import("./components/Mobile/FreshListItems"),
    loading: () => <Loading />,
    render: (loaded, props) => {
      if (props) {
        return <WithRouterPass {...props} component={loaded.default} />;
      } else {
        return <WithRouterPass component={loaded.default} />;
      }
    },
  }
);

export const SingleItem = Loadable({
  loader: () => import("./components/Mobile/Items/SingleItem"),
  loading: () => <Loading />,
  render: (loaded, props) => {
    if (props) {
      return <WithRouterPass {...props} component={loaded.default} />;
    } else {
      return <WithRouterPass component={loaded.default} />;
    }
  },
});

export const CakeList = Loadable({
  loader: () => import("./components/Mobile/Home/CakeList"),
  loading: () => <Loading />,
  render: (loaded, props) => {
    if (props) {
      return <WithRouterPass {...props} component={loaded.default} />;
    } else {
      return <WithRouterPass component={loaded.default} />;
    }
  },
});

export const OfferSection = Loadable({
  loader: () => import("./components/Mobile/OfferSection"),
  loading: () => <Loading />,
  render: (loaded, props) => {
    if (props) {
      return <WithRouterPass {...props} component={loaded.default} />;
    } else {
      return <WithRouterPass component={loaded.default} />;
    }
  },
});

export const OfferTag = Loadable({
  loader: () => import("./components/Mobile/OurOffer/OfferTag"),
  loading: () => <Loading />,
  render: (loaded, props) => {
    if (props) {
      return <WithRouterPass {...props} component={loaded.default} />;
    } else {
      return <WithRouterPass component={loaded.default} />;
    }
  },
});

export const Account = Loadable({
  loader: () => import("./components/Mobile/Account"),
  loading: () => <Loading />,
  render: (loaded, props) => {
    if (props) {
      return <WithRouterPass {...props} component={loaded.default} />;
    } else {
      return <WithRouterPass component={loaded.default} />;
    }
  },
});

export const Settings = Loadable({
  loader: () => import("./components/Mobile/Account/Settings"),
  loading: () => <Loading />,
  render: (loaded, props) => {
    if (props) {
      return <WithRouterPass {...props} component={loaded.default} />;
    } else {
      return <WithRouterPass component={loaded.default} />;
    }
  },
});

export const AllPayments = Loadable({
  loader: () => import("./components/Mobile/Account/ClubSubscription/SubscribedUser"),
  loading: () => <Loading />,
  render: (loaded, props) => {
    if (props) {
      return <WithRouterPass {...props} component={loaded.default} />;
    } else {
      return <WithRouterPass component={loaded.default} />;
    }
  },
});

export const Alerts = Loadable({
  loader: () => import("./components/Mobile/Alerts"),
  loading: () => <Loading />,
  render: (loaded, props) => {
    if (props) {
      return <WithRouterPass {...props} component={loaded.default} />;
    } else {
      return <WithRouterPass component={loaded.default} />;
    }
  },
});

export const EatikoDineOuts = Loadable({
  loader: () => import("./components/Mobile/EatikoDineOuts"),
  loading: () => <Loading />,
  render: (loaded, props) => {
    if (props) {
      return <WithRouterPass {...props} component={loaded.default} />;
    } else {
      return <WithRouterPass component={loaded.default} />;
    }
  },
});

export const Rewards = Loadable({
  loader: () => import("./components/Mobile/Account/Rewards"),
  loading: () => <Loading />,
  render: (loaded, props) => {
    if (props) {
      return <WithRouterPass {...props} component={loaded.default} />;
    } else {
      return <WithRouterPass component={loaded.default} />;
    }
  },
});

export const Orders = Loadable({
  loader: () => import("./components/Mobile/Account/Orders"),
  loading: () => <Loading />,
  render: (loaded, props) => {
    if (props) {
      return <WithRouterPass {...props} component={loaded.default} />;
    } else {
      return <WithRouterPass component={loaded.default} />;
    }
  },
});

export const RatingAndReview = Loadable({
  loader: () => import("./components/Mobile/Account/Orders/RatingAndReview"),
  loading: () => <Loading />,
  render: (loaded, props) => {
    if (props) {
      return <WithRouterPass {...props} component={loaded.default} />;
    } else {
      return <WithRouterPass component={loaded.default} />;
    }
  },
});

export const WalletPage = Loadable({
  loader: () => import("./components/Mobile/Account/Wallet"),
  loading: () => <Loading />,
  render: (loaded, props) => {
    if (props) {
      return <WithRouterPass {...props} component={loaded.default} />;
    } else {
      return <WithRouterPass component={loaded.default} />;
    }
  },
});

export const Ecoin = Loadable({
  loader: () => import("./components/Mobile/Account/Ecoin"),
  loading: () => <Loading />,
  render: (loaded, props) => {
    if (props) {
      return <WithRouterPass {...props} component={loaded.default} />;
    } else {
      return <WithRouterPass component={loaded.default} />;
    }
  },
});

export const ClubUserSubscriptionSuccess = Loadable({
  loader: () => import("./components/Mobile/Account/ClubSubscription/PaymentStatus"),
  loading: () => <Loading />,
  render: (loaded, props) => {
    if (props) {
      return <WithRouterPass {...props} component={loaded.default} />;
    } else {
      return <WithRouterPass component={loaded.default} />;
    }
  },
});

export const Suggestions = Loadable({
  loader: () => import("./components/Mobile/Home/Suggestion"),
  loading: () => <Loading />,
  render: (loaded, props) => {
    if (props) {
      return <WithRouterPass {...props} component={loaded.default} />;
    } else {
      return <WithRouterPass component={loaded.default} />;
    }
  },
});

export const About = Loadable({
  loader: () => import("./components/Mobile/Account/ClubMenu/About"),
  loading: () => <Loading />,
  render: (loaded, props) => {
    if (props) {
      return <WithRouterPass {...props} component={loaded.default} />;
    } else {
      return <WithRouterPass component={loaded.default} />;
    }
  },
});

export const Profile = Loadable({
  loader: () => import("./components/Mobile/Account/Profile"),
  loading: () => <Loading />,
  render: (loaded, props) => {
    if (props) {
      return <WithRouterPass {...props} component={loaded.default} />;
    } else {
      return <WithRouterPass component={loaded.default} />;
    }
  },
});

export const Checkout = Loadable({
  loader: () => import("./components/Mobile/Checkout"),
  loading: () => <Loading />,
  render: (loaded, props) => {
    if (props) {
      return <WithRouterPass {...props} component={loaded.default} />;
    } else {
      return <WithRouterPass component={loaded.default} />;
    }
  },
});

export const AvailableCoupons = Loadable({
  loader: () => import("./components/Mobile/Cart/Coupon/AvailableCoupons"),
  loading: () => <Loading />,
  render: (loaded, props) => {
    if (props) {
      return <WithRouterPass {...props} component={loaded.default} />;
    } else {
      return <WithRouterPass component={loaded.default} />;
    }
  },
});

export const MoreDataView = Loadable({
  loader: () => import("./components/Mobile/MoreDataView"),
  loading: () => <Loading />,
  render: (loaded, props) => {
    if (props) {
      return <WithRouterPass {...props} component={loaded.default} />;
    } else {
      return <WithRouterPass component={loaded.default} />;
    }
  },
});

export const RunningOrder = Loadable({
  loader: () => import("./components/Mobile/RunningOrder"),
  loading: () => <Loading />,
  render: (loaded, props) => {
    if (props) {
      return <WithRouterPass {...props} component={loaded.default} />;
    } else {
      return <WithRouterPass component={loaded.default} />;
    }
  },
});

export const AccountSettings = Loadable({
  loader: () => import("./components/Mobile/Account/AccountSettings"),
  loading: () => <Loading />,
  render: (loaded, props) => {
    if (props) {
      return <WithRouterPass {...props} component={loaded.default} />;
    } else {
      return <WithRouterPass component={loaded.default} />;
    }
  },
});

export const ClubBuyList = Loadable({
  loader: () => import("./components/Mobile/Home1/HomePage/ClubBuy/ClubBuyList"),
  loading: () => <Loading />,
  render: (loaded, props) => {
    if (props) {
      return <WithRouterPass {...props} component={loaded.default} />;
    } else {
      return <WithRouterPass component={loaded.default} />;
    }
  },
});

export const RecipieRoot = Loadable({
  loader: () => import("./components/Mobile/Recipies"),
  loading: () => <Loading />,
  render: (loaded, props) => {
    if (props) {
      return <WithRouterPass {...props} component={loaded.default} />;
    } else {
      return <WithRouterPass component={loaded.default} />;
    }
  },
});

export const RecipieSingleRoot = Loadable({
  loader: () => import("./components/Mobile/Recipies/SingleView"),
  loading: () => <Loading />,
  render: (loaded, props) => {
    if (props) {
      return <WithRouterPass {...props} component={loaded.default} />;
    } else {
      return <WithRouterPass component={loaded.default} />;
    }
  },
});

export const ExploreRecipie = Loadable({
  loader: () => import("./components/Mobile/Recipies/RecipeExplore"),
  loading: () => <Loading />,
  render: (loaded, props) => {
    if (props) {
      return <WithRouterPass {...props} component={loaded.default} />;
    } else {
      return <WithRouterPass component={loaded.default} />;
    }
  },
});

export const RecipiesAllPage = Loadable({
  loader: () => import("./components/Mobile/Recipies/RecipiesAllPage"),
  loading: () => <Loading />,
  render: (loaded, props) => {
    if (props) {
      return <WithRouterPass {...props} component={loaded.default} />;
    } else {
      return <WithRouterPass component={loaded.default} />;
    }
  },
});

export const SavedRecipies = Loadable({
  loader: () => import("./components/Mobile/Recipies/SavedRecipies"),
  loading: () => <Loading />,
  render: (loaded, props) => {
    if (props) {
      return <WithRouterPass {...props} component={loaded.default} />;
    } else {
      return <WithRouterPass component={loaded.default} />;
    }
  },
});

export const RecipieHeaderItems = Loadable({
  loader: () => import("./components/Mobile/Recipies/RecipeHeaderItems"),
  loading: () => <Loading />,
  render: (loaded, props) => {
    if (props) {
      return <WithRouterPass {...props} component={loaded.default} />;
    } else {
      return <WithRouterPass component={loaded.default} />;
    }
  },
});

export const RecipieBannerItems = Loadable({
  loader: () => import("./components/Mobile/Recipies/RecipesBannerItems"),
  loading: () => <Loading />,
  render: (loaded, props) => {
    if (props) {
      return <WithRouterPass {...props} component={loaded.default} />;
    } else {
      return <WithRouterPass component={loaded.default} />;
    }
  },
});

export const RecipieCookByDishes = Loadable({
  loader: () => import("./components/Mobile/Recipies/RecipieCookByDishes"),
  loading: () => <Loading />,
  render: (loaded, props) => {
    if (props) {
      return <WithRouterPass {...props} component={loaded.default} />;
    } else {
      return <WithRouterPass component={loaded.default} />;
    }
  },
});

export const DietPlan = Loadable({
  loader: () => import("./components/Mobile/DietPlan"),
  loading: () => <Loading />,
  render: (loaded, props) => {
    if (props) {
      return <WithRouterPass {...props} component={loaded.default} />;
    } else {
      return <WithRouterPass component={loaded.default} />;
    }
  },
});

export const DietFeatured = Loadable({
  loader: () => import("./components/Mobile/DietPlan/DietFeatured"),
  loading: () => <Loading />,
  render: (loaded, props) => {
    if (props) {
      return <WithRouterPass {...props} component={loaded.default} />;
    } else {
      return <WithRouterPass component={loaded.default} />;
    }
  },
});

export const DietPlanAll = Loadable({
  loader: () => import("./components/Mobile/DietPlan/DietPlanAll"),
  loading: () => <Loading />,
  render: (loaded, props) => {
    if (props) {
      return <WithRouterPass {...props} component={loaded.default} />;
    } else {
      return <WithRouterPass component={loaded.default} />;
    }
  },
});

export const DietExplore = Loadable({
  loader: () => import("./components/Mobile/DietPlan/DietExplore"),
  loading: () => <Loading />,
  render: (loaded, props) => {
    if (props) {
      return <WithRouterPass {...props} component={loaded.default} />;
    } else {
      return <WithRouterPass component={loaded.default} />;
    }
  },
});

export const DietRestaurantList = Loadable({
  loader: () => import("./components/Mobile/DietPlan/DietRestaurantList"),
  loading: () => <Loading />,
  render: (loaded, props) => {
    if (props) {
      return <WithRouterPass {...props} component={loaded.default} />;
    } else {
      return <WithRouterPass component={loaded.default} />;
    }
  },
});

export const DietPlanSingle = Loadable({
  loader: () => import("./components/Mobile/DietPlan/DietPlanSingle"),
  loading: () => <Loading />,
  render: (loaded, props) => {
    if (props) {
      return <WithRouterPass {...props} component={loaded.default} />;
    } else {
      return <WithRouterPass component={loaded.default} />;
    }
  },
});

export const DietSingleRestaurant = Loadable({
  loader: () => import("./components/Mobile/DietPlan/DietSingleRestaurant"),
  loading: () => <Loading />,
  render: (loaded, props) => {
    if (props) {
      return <WithRouterPass {...props} component={loaded.default} />;
    } else {
      return <WithRouterPass component={loaded.default} />;
    }
  },
});

export const SingleDiningView = Loadable({
  loader: () => import("./components/Mobile/EatikoDineOuts/SingleDiningView"),
  loading: () => <Loading />,
  render: (loaded, props) => {
    if (props) {
      return <WithRouterPass {...props} component={loaded.default} />;
    } else {
      return <WithRouterPass component={loaded.default} />;
    }
  },
});

export const CategoryWiseDineOuts = Loadable({
  loader: () =>
    import("./components/Mobile/EatikoDineOuts/CategoryWiseDineOuts/index1.js"),
  loading: () => <Loading />,
  render: (loaded, props) => {
    if (props) {
      return <WithRouterPass {...props} component={loaded.default} />;
    } else {
      return <WithRouterPass component={loaded.default} />;
    }
  },
});

export const DineoutCollectionStores = Loadable({
  loader: () =>
    import(
      "./components/Mobile/EatikoDineOuts/DineoutCollections/DineoutCollectionStores"
    ),
  loading: () => <Loading />,
  render: (loaded, props) => {
    if (props) {
      return <WithRouterPass {...props} component={loaded.default} />;
    } else {
      return <WithRouterPass component={loaded.default} />;
    }
  },
});

export const FoodSpotSingle = Loadable({
  loader: () =>
    import("./components/Mobile/CommonHome/FoodSpots/FoodSpotSingle"),
  loading: () => <Loading />,
  render: (loaded, props) => {
    if (props) {
      return <WithRouterPass {...props} component={loaded.default} />;
    } else {
      return <WithRouterPass component={loaded.default} />;
    }
  },
});

export const NotFound = Loadable({
  loader: () => import("./components/NotFound"),
  loading: () => <Loading />,
  render: (loaded, props) => {
    if (props) {
      return <WithRouterPass {...props} component={loaded.default} />;
    } else {
      return <WithRouterPass component={loaded.default} />;
    }
  },
});

export const Fresh = Loadable({
  loader: () => import("./components/Mobile/Fresh"),
  loading: () => <Loading />,
  render: (loaded, props) => {
    if (props) {
      return <WithRouterPass {...props} component={loaded.default} />;
    } else {
      return <WithRouterPass component={loaded.default} />;
    }
  },
});

export const FreshExplore = Loadable({
  loader: () => import("./components/Mobile/Fresh/FreshExplore"),
  loading: () => <Loading />,
  render: (loaded, props) => {
    if (props) {
      return <WithRouterPass {...props} component={loaded.default} />;
    } else {
      return <WithRouterPass component={loaded.default} />;
    }
  },
});

export const EatikoLook = Loadable({
  loader: () => import("./components/Mobile/EatikoLook"),
  loading: () => <Loading />,
  render: (loaded, props) => {
    if (props) {
      return <WithRouterPass {...props} component={loaded.default} />;
    } else {
      return <WithRouterPass component={loaded.default} />;
    }
  },
});

export const EatikoLookByCity = Loadable({
  loader: () => import("./components/Mobile/EatikoLook/FoodSpotByCity"),
  loading: () => <Loading />,
  render: (loaded, props) => {
    if (props) {
      return <WithRouterPass {...props} component={loaded.default} />;
    } else {
      return <WithRouterPass component={loaded.default} />;
    }
  },
});

export const EatikoLookCities = Loadable({
  loader: () => import("./components/Mobile/EatikoLook/EatikoLookCities"),
  loading: () => <Loading />,
  render: (loaded, props) => {
    if (props) {
      return <WithRouterPass {...props} component={loaded.default} />;
    } else {
      return <WithRouterPass component={loaded.default} />;
    }
  },
});

export const EatikoLookByCategory = Loadable({
  loader: () => import("./components/Mobile/EatikoLook/EatikoLookByCategory"),
  loading: () => <Loading />,
  render: (loaded, props) => {
    if (props) {
      return <WithRouterPass {...props} component={loaded.default} />;
    } else {
      return <WithRouterPass component={loaded.default} />;
    }
  },
});

export const EatikoLookExplore = Loadable({
  loader: () => import("./components/Mobile/EatikoLook/EatikoLookExplore"),
  loading: () => <Loading />,
  render: (loaded, props) => {
    if (props) {
      return <WithRouterPass {...props} component={loaded.default} />;
    } else {
      return <WithRouterPass component={loaded.default} />;
    }
  },
});

export const EatikoLookLiked = Loadable({
  loader: () => import("./components/Mobile/EatikoLook/EatikoLookLiked"),
  loading: () => <Loading />,
  render: (loaded, props) => {
    if (props) {
      return <WithRouterPass {...props} component={loaded.default} />;
    } else {
      return <WithRouterPass component={loaded.default} />;
    }
  },
});

export const EatikoLookVerified = Loadable({
  loader: () => import("./components/Mobile/EatikoLook/EatikoLookVerified"),
  loading: () => <Loading />,
  render: (loaded, props) => {
    if (props) {
      return <WithRouterPass {...props} component={loaded.default} />;
    } else {
      return <WithRouterPass component={loaded.default} />;
    }
  },
});

export const EatikoLookBestItems = Loadable({
  loader: () => import("./components/Mobile/EatikoLook/BestFoodSpots/BestItemsList"),
  loading: () => <Loading />,
  render: (loaded, props) => {
    if (props) {
      return <WithRouterPass {...props} component={loaded.default} />;
    } else {
      return <WithRouterPass component={loaded.default} />;
    }
  },
});

export const TopRatedFoodSpotList = Loadable({
  loader: () => import("./components/Mobile/EatikoLook/TopRatedFoodSpots/TopRatedList"),
  loading: () => <Loading />,
  render: (loaded, props) => {
    if (props) {
      return <WithRouterPass {...props} component={loaded.default} />;
    } else {
      return <WithRouterPass component={loaded.default} />;
    }
  },
});

export const ReviewEatikoLook = Loadable({
  loader: () => import("./components/Mobile/EatikoLook/ReviewEatikoLook"),
  loading: () => <Loading />,
  render: (loaded, props) => {
    if (props) {
      return <WithRouterPass {...props} component={loaded.default} />;
    } else {
      return <WithRouterPass component={loaded.default} />;
    }
  },
});

export const EatikoLookMissingSptForm = Loadable({
  loader: () => import("./components/Mobile/EatikoLook/MissingSpotForm"),
  loading: () => <Loading />,
  render: (loaded, props) => {
    if (props) {
      return <WithRouterPass {...props} component={loaded.default} />;
    } else {
      return <WithRouterPass component={loaded.default} />;
    }
  },
});
