import moment from 'moment';
import React, { Component, createContext, useContext, useEffect, useState } from 'react';
import { RiArrowUpCircleLine } from 'react-icons/ri';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router';
import { toast } from 'react-toastify';
import bluePlaceHolder from '../../assets/blue_placeholder.jpg';
import logoblack from '../../assets/images/logos/logoblack.png';
import logowhite from '../../assets/images/logos/logowhite.png';
import { getCurrentOrderCount } from '../../services/cart/actions';
import * as serviceWorkerRegistration from '../../serviceWorkerRegistration';

export const textTruncate = (str, length, ending) => {
  if (length == null) {
    length = 100;
  }
  if (ending == null) {
    ending = "...";
  }
  if (str.length > length) {
    return str.substring(0, length - ending.length) + ending;
  } else {
    return str;
  }
};

export const getCurrentMainVersion = (version) => {
  try {
    let array = version.split('.');
    return parseInt(array[array.length - 1]);
  } catch (error) {
    return 0;
  }
}

export const isValidDate = (d) => {
  return d instanceof Date && !isNaN(d.getTime());
}

export const getAppTimeOfUpdation = () => {
  try {
    const metaAppVersionTime = document.querySelector('meta[name="version-time"]')?.getAttribute('build-version');
    const firstChar = metaAppVersionTime.charAt(0);
    const timeExact = (firstChar === '-' ? 'T' : firstChar) + metaAppVersionTime.slice(1);
    const lastds = timeExact.replace('-', ':');
    const lastdss = lastds.slice(0, -1) + 'T';
    const dateData = new Date(lastdss);
    return isValidDate(dateData) ? dateData.toUTCString() : '';
  } catch (error) {
    return '';
  }
}

export const successToast = (msg) => {
  toast.success(msg, {
    position: "top-center",
    autoClose: 1000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "dark",
  });
}

export const errorToast = (msg) => {
  toast.error(msg, {
    position: "top-center",
    autoClose: 1000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "dark",
  });
}

export const iOS = () => {
  return [
    "iPad Simulator",
    "iPhone Simulator",
    "iPod Simulator",
    "iPad",
    "iPhone",
    "iPod"
  ].includes(navigator.platform) ||
    (navigator.userAgent.includes("Mac") && "ontouchend" in document);
}

export const setNewCashfreePackge = () => {
  let version = getCurrentMainVersion(localStorage.getItem("appversion")),
    is_ios = iOS() ? true : false;
  let is_react_native = is_ios ? version >= 100 : version > 115;
  return is_react_native;
}

export const setNewJuspayPayment = () => {
  let version = getCurrentMainVersion(localStorage.getItem("appversion")),
    is_ios = iOS() ? true : false;
  let is_react_native = is_ios ? version >= 102 : version > 126;
  return is_react_native;
}

export const getNumberToPositonString = (n) => {
  var special = ['zeroth', 'first', 'second', 'third', 'fourth', 'fifth', 'sixth', 'seventh', 'eighth', 'ninth', 'tenth', 'eleventh', 'twelfth', 'thirteenth', 'fourteenth', 'fifteenth', 'sixteenth', 'seventeenth', 'eighteenth', 'nineteenth'];
  var deca = ['twent', 'thirt', 'fort', 'fift', 'sixt', 'sevent', 'eight', 'ninet'];
  if (n < 20) return special[n];
  if (n % 10 === 0) return deca[Math.floor(n / 10) - 2] + 'ieth';
  return deca[Math.floor(n / 10) - 2] + 'y-' + special[n % 10];
}

export const setWhatsAppChatButton = (enabled = true, order_count = 0) => {
  try {
    if (enabled == true) {
      document.getElementById('whatsapp-chat-widget').style.display = 'block';
    } else {
      document.getElementById('whatsapp-chat-widget').style.display = 'none';
    }
    const widget = document.querySelector('.wa-widget-send-button');
    if (order_count > 0) {
      widget.classList.add("order-count");
    } else {
      widget.classList.remove("order-count");
    }
  } catch (error) {
  }
}

export const sendStoreBackgroundService = (enabled = true) => {
  try {
    if (window.ReactNativeWebView) {
      const response = {
        type: 'store_running',
        data: JSON.stringify({ status: enabled, message: "Your store is running on Eatiko" })
      }
      window.ReactNativeWebView.postMessage(JSON.stringify(response));
    } else if (document.ReactNativeWebView) {
      const response = {
        type: 'store_running',
        data: JSON.stringify({ status: enabled, message: "Your store is running on Eatiko" })
      }
      document.ReactNativeWebView.postMessage(JSON.stringify(response));
    }
  } catch (error) {
  }
}

export const sendCustomerToken = (token) => {
  try {
    if (window.ReactNativeWebView) {
      const response = {
        type: 'state',
        data: { customerAccessToken: token }
      }
      window.ReactNativeWebView.postMessage(JSON.stringify(response));
    } else if (document.ReactNativeWebView) {
      const response = {
        type: 'state',
        data: { customerAccessToken: token }
      }
      document.ReactNativeWebView.postMessage(JSON.stringify(response));
    }
  } catch (error) {
  }
}

export const sendCustomerToken2 = (token) => {
  try {
    if (window.ReactNativeWebView) {
      const response = {
        type: 'token',
        data: token
      }
      window.ReactNativeWebView.postMessage(JSON.stringify(response));
    } else if (document.ReactNativeWebView) {
      const response = {
        type: 'token',
        data: token
      }
      document.ReactNativeWebView.postMessage(JSON.stringify(response));
    }
  } catch (error) {
  }
}

export const sendDataToReactNative = (type, data) => {
  try {
    const response = {
      type: type,
      data: data
    }
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage(JSON.stringify(response));
    } else if (document.ReactNativeWebView) {
      document.ReactNativeWebView.postMessage(JSON.stringify(response));
    }
  } catch (error) {
  }
}

export const setNewTheme = () => {
  return false;
}

export const setDarkAndNightMode = (darkMode = true) => {
  document.body.className = '';
  if (darkMode) {
    document.body.classList.add("dark");
    return true;
  } else {
    document.body.classList.add("white");
    return false;
  }
}

export const getLogo = () => {
  var isDark = document.getElementsByTagName("body")[0].className.match(/dark/)
  if (isDark) {
    return logowhite;
  } else {
    return logoblack;
  }
}

export const isMidnightSale = () => {
  var startTime = new Date();
  startTime.setFullYear(2022);
  startTime.setMonth(11);
  startTime.setDate(31);
  startTime.setHours(23, 0, 0);
  var endTime = new Date();
  endTime.setFullYear(2023);
  endTime.setMonth(0);
  endTime.setDate(1);
  endTime.setHours(1, 0, 0);
  var currentTime = new Date();
  return startTime <= currentTime && endTime >= currentTime;
}

export const getDayTimeWish = () => {
  var data = [
    [0, 4, "Good day"],
    [5, 11, "Good morning"],
    [12, 17, "Good afternoon"],
    [18, 24, "Good evening"]
  ],
    hr = new Date().getHours();
  for (var i = 0; i < data.length; i++) {
    if (hr >= data[i][0] && hr <= data[i][1]) {
      return data[i][2];
    }
  }
}

export const getFoodTimes = () => {
  return "You are ";
  var data = [
    [0, 4, "It's supper time"],
    [5, 11, "It's break-fast time"],
    [12, 17, "It's Lunch time"],
    [18, 24, "It's Dinner time"]
  ],
    hr = new Date().getHours();
  for (var i = 0; i < data.length; i++) {
    if (hr >= data[i][0] && hr <= data[i][1]) {
      return data[i][2];
    }
  }
}

export const getDayTimePlan = () => {
  var data = [
    [0, 4, "Plan your dish"],
    [5, 11, "Plan your break-fast now"],
    [12, 17, "Plan your lunch now"],
    [18, 24, "Plan your dinner now"]
  ],
    hr = new Date().getHours();
  for (var i = 0; i < data.length; i++) {
    if (hr >= data[i][0] && hr <= data[i][1]) {
      return data[i][2];
    }
  }
}

export const checkCityDelayTiming = () => {
  if (localStorage.getItem("last_city_api")) {
    // start time and end time
    var startTime = moment(localStorage.getItem("last_city_api"));
    var endTime = moment(new Date());

    // calculate total duration
    var duration = moment.duration(endTime.diff(startTime));
    // var minutes = parseInt(duration.asMinutes())%60;
    var hours = parseInt(duration.asHours());
    var minutes = parseInt(duration.asMinutes()) % 60;

    var finalMinute = hours * 60 + minutes;
    if (finalMinute >= 5) {
      return true;
    } else {
      return false;
    }
  } else {
    return true;
  }
}

export const firstAndLastDayOfTheWeek = () => {
  return [moment().startOf('week').add(1, 'days').toDate(), moment().endOf('week').add(1, 'days').toDate()];
}

export const removeRsuiteCss = (status = true) => {
  const styleSheets = document.styleSheets;
  for (let index = 0; index < styleSheets.length; index++) {
    try {
      const cssRules = styleSheets[index].cssRules;
      for (let index2 = 0; index2 < cssRules.length; index2++) {
        if (cssRules[index2].selectorText && cssRules[index2].selectorText.includes('.rs-')) {
          try {
            styleSheets[index].disabled = status;
          } catch (error) {
          }
        }
      }
    } catch (error) {
    }
  }
}

export const convertDBDate = (date) => {
  const currentDate = new Date();
  const splitDates = date.split('-');
  currentDate.setDate(splitDates[splitDates.length - 1]);
  currentDate.setMonth(splitDates[splitDates.length - 2] - 1);
  currentDate.setFullYear(splitDates[splitDates.length - 3]);
  return currentDate;
}

export const gotoUrlPage = (uri) => {
  const HOC = class extends Component {
    componentDidMount() {
      // this.context.router.history.push(uri)
    }
  }
  return HOC;
}

export const useProgressiveImage = src => {
  const [sourceLoaded, setSourceLoaded] = useState(null)

  useEffect(() => {
    const img = new Image()
    img.src = src
    img.onload = () => setSourceLoaded(src)
  }, [src])

  return sourceLoaded
}

export const placeholderImage = bluePlaceHolder;

export const shuffle = (array) => {
  for (var i = array.length - 1; i > 0; i--) {
    var j = Math.floor(Math.random() * (i + 1));
    var temp = array[i];
    array[i] = array[j];
    array[j] = temp;
  }
};

export const removeCoupons = () => {
  try {
    if (localStorage.getItem("appliedCoupon")) localStorage.removeItem("appliedCoupon");
    if (localStorage.getItem("coupon_discount")) localStorage.removeItem("coupon_discount");
    if (localStorage.getItem("qidValue")) localStorage.removeItem("qidValue");
    if (localStorage.getItem("couponRestaurant")) localStorage.removeItem("couponRestaurant");
    alert(1123)
  } catch (error) {
  }
}

export const isWeekend = (date) => {
  return moment(date).day() == 6 || moment(date).day() == 0;
}

export const getQueryParam = (name) => {
  const searchParams = new URLSearchParams(window.location.search);
  return searchParams.get(name);
}

export const getPercentageOfTheOffer = (old_price, price) => {
  try {
    return parseFloat(((parseFloat(old_price) - parseFloat(price)) / parseFloat(old_price)) * 100).toFixed(0);
  } catch (error) {
    return 0;
  }
}

export const Navigater = (path = '/') => {
  return <Navigate to={path} replace />;
}

export const SystemContext = createContext(null);
export const useSystemContext = () => {
  return useContext(SystemContext);
};

export const getRestaurantOffer = (text) => {
  return text.includes("OFF") ? text : text + "% OFF";
}

export const multipleDeliveryCost = () => {
  try {
    const city = JSON.parse(localStorage.getItem("city"));
    return parseFloat(city.muli_delivery_cost);
  } catch (error) {
    try {
      const city = JSON.parse(localStorage.getItem("userSetAddress")).city;
      return parseFloat(city.muli_delivery_cost);
    } catch (error) {
      return 0;
    }
  }
}

export const wordChecker = (sentence, word) => {
  const regex = new RegExp(`\\b${word}\\b`, 'i');
  const isWordIncluded = regex.test(sentence);
  return isWordIncluded;
}

export const cdnImageUrl = localStorage.getItem("primary_cdn_orgin") ? localStorage.getItem("primary_cdn_orgin") : 'https://eatiko.com';

export const getRandomNumber = (min, max) => {
  const randomFraction = Math.random();
  const randomNumber = min + randomFraction * (max - min);
  return parseInt(randomNumber);
}

export const getCityAccess = (pages) => {
  try {
    const foodDeliveryPages = pages?.filter((item) => item?.slug.toLowerCase().includes('food-delivery')) || [];
    return foodDeliveryPages.length > 0;
  } catch (error) {
    return false;
  }
}

export const getRestaurantsFromCartProducts = (products) => {
  let restaurants = [];
  try {
    products.forEach((product) => {
      if (!restaurants.includes(product.restaurant_id)) {
        restaurants.push(product.restaurant_id);
      }
    });
  } catch (error) {
  }
  return restaurants;
}

export const isIOS = () => {
  var userAgent = navigator.userAgent || navigator.vendor || window.opera;
  return /iPad|iPhone|iPod/.test(userAgent) && !window.MSStream;
}

export const BackToTopButton = (props) => {
  const [isScrollTop, setIsScrollTop] = useState(false);
  const scrollTop = () => {
    try {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    } catch (error) {
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", (e) => {
      const scrollY = window.scrollY;
      setIsScrollTop(scrollY > 750);
    });

    return () => {
      window.removeEventListener("scroll", (e) => setIsScrollTop(false));
    };
  }, []);
  return (isScrollTop ?
    <div
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        zIndex: 999,
      }}
      className='d-flex flex-column align-items-center justify-content-center w-100 py-4'>
      <div
        className='d-flex btn-press-effect align-items-center justify-content-center px-3 py-1'
        style={{
          background: '#121212',
          color: '#fafafa',
          fontWeight: '500',
          fontSize: '0.98rem',
          borderRadius: '1.148rem',
        }}
        onClick={scrollTop}
      >
        <span className='pr-1 pt-1'><RiArrowUpCircleLine size={20} /></span>
        <span>Back to Top</span>
      </div>
    </div>
    : null)
}

export const RemoveWhatsAppChatButton = (props) => {
  useEffect(() => {
    const intervalId = setInterval(() => {
      setWhatsAppChatButton(false);
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);
  return <React.Fragment></React.Fragment>
}

export const RemoveAppBadge = (props) => {
  useEffect(() => {
    const intervalId = setInterval(() => {
      try {
        let elements = document.getElementsByClassName('app-install-badge');
        for (let i = 0; i < elements.length; i++) {
          elements[i].style.display = 'none';
        }
      } catch (error) {
      }
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);
  return <React.Fragment></React.Fragment>
}

export const AddWhatsAppChatButton = (props) => {
  const order_count = useSelector(getCurrentOrderCount)
  useEffect(() => {
    const intervalId = setInterval(() => {
      setWhatsAppChatButton(true, order_count);
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);
  return <React.Fragment></React.Fragment>
}

export const transparentPlaceHolder = `${cdnImageUrl}/assets/normal-images/blank-image.png`;

export const handleClearCache = async () => {
  // console.log('clear cache wokrks');
  try {
    serviceWorkerRegistration.unregister();
    serviceWorkerRegistration.register();
  } catch (error) {
  }
  try {
    if ('serviceWorker' in navigator) {
      const registration = await navigator.serviceWorker.getRegistration();
      if (registration && registration.waiting) {
        registration.waiting.postMessage({ type: 'SKIP_WAITING' });
        // console.log('no error');
        window.location.reload();
      } else {
        // console.log('sw-error: waiting not found');
      }
    } else {
      // console.log('sw-error: not found');
      if ('serviceWorker' in navigator && navigator.serviceWorker.controller) {
        navigator.serviceWorker.controller.postMessage({ type: 'SKIP_WAITING' });
      }
    }
  } catch (error) {
    // console.log(error, 'sw-error');
    if ('serviceWorker' in navigator && navigator.serviceWorker.controller) {
      navigator.serviceWorker.controller.postMessage({ type: 'SKIP_WAITING' });
    }
  }
};

export const isThisFlashDeals = (products) => {
  const item = products.find((item) => item.flash_deals && item.flash_deals.id)
  return item;
}

export const airDistance = (latitudeFrom, longitudeFrom, latitudeTo, longitudeTo) => {
  try {
    const toRadians = (degrees) => degrees * (Math.PI / 180);

    const latFrom = toRadians(latitudeFrom);
    const lonFrom = toRadians(longitudeFrom);
    const latTo = toRadians(latitudeTo);
    const lonTo = toRadians(longitudeTo);

    const latDelta = latTo - latFrom;
    const lonDelta = lonTo - lonFrom;

    const angle = 2 * Math.asin(Math.sqrt(
      Math.pow(Math.sin(latDelta / 2), 2) +
      Math.cos(latFrom) * Math.cos(latTo) * Math.pow(Math.sin(lonDelta / 2), 2)
    ));

    return angle * 6371000; // Distance in kilometers
  } catch (e) {
    console.error(e);
  }
}

export const checkCityTimingCheck = () => {
  if (localStorage.getItem("last_city_api")) {
    // start time and end time
    var startTime = moment(localStorage.getItem("last_city_api"));
    var endTime = moment(new Date());

    // calculate total duration
    var duration = moment.duration(endTime.diff(startTime));
    var hours = parseInt(duration.asHours());
    var minutes = parseInt(duration.asMinutes()) % 60;

    var finalMinute = hours * 60 + minutes;
    if (finalMinute >= 5) {
      // if (finalMinute >= 0) {
      return true;
    } else {
      return false;
    }
  } else {
    return true;
  }
}

export const getDevice = () => {
  var userAgent = navigator.userAgent || navigator.vendor || window.opera;
  if (window.ReactNativeWebView) {
    if (/android/i.test(userAgent)) {
      return "Android";
    }
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return "iOS";
    }
    if (userAgent.includes("Mozilla/5.0 (Linux; Android 6.0; Nexus 5 Build/MRA58N) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/62.0.3202.94 Mobile Safari/537.36")) {
      return "iOS"
    }
    return "Website";
  } else {
    if (userAgent.includes("Mozilla/5.0 (Linux; Android 6.0; Nexus 5 Build/MRA58N) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/62.0.3202.94 Mobile Safari/537.36")) {
      return "iOS"
    }
    return "Website";
  }
};