import { createSlice } from '@reduxjs/toolkit';
import * as dietActions from './dietActions';
import * as dietReducerData from './dietReducerData';

const initialState = {
  dietPlansHome: {
    loading: false,
    data: null,
    message: 'Not Loaded',
    error: null,
    status: false,
  },
  dietBanners: {
    loading: false,
    data: null,
    message: 'Not Loaded',
    error: null,
    status: false,
  },
  dietCuratedRestaurants: {
    loading: false,
    data: null,
    message: 'Not Loaded',
    error: null,
    status: false,
  },
  dietPlan: {
    loading: false,
    data: null,
    message: 'Not Loaded',
    error: null,
    status: false,
  },
  dietBanner: {
    loading: false,
    data: null,
    message: 'Not Loaded',
    error: null,
    status: false,
  },
  singleRestaurant: { ...dietActions.defaultFoodSpots },
};

const dietPlanSlice = createSlice({
  name: 'dietPlan',
  initialState,
  reducers: {
    clearDietPlanHome: (state) => dietActions.clearDietPlanHome(state),
    clearDietBanner: (state) => dietActions.clearDietBanner(state),
    clearDietCuratedRestaurants: (state) => dietActions.clearDietCuratedRestaurants(state),
    clearDietPlanSingle: (state) => dietActions.clearDietPlanSingle(state),
    clearDietBannerSingle: (state) => dietActions.clearDietBannerSingle(state),
    clearSingleRestaurant: (state) => dietActions.clearSingleRestaurant(state),
  },
  extraReducers: (builder) => {
    builder
      .addCase(dietActions.getDietPlanHomeRequest.pending, (state, action) =>
        dietReducerData.requestDataForDietPlanHome(state, action, 'pending'),
      )
      .addCase(dietActions.getDietPlanHomeRequest.fulfilled, (state, action) =>
        dietReducerData.requestDataForDietPlanHome(state, action, 'fulfilled'),
      )
      .addCase(dietActions.getDietPlanHomeRequest.rejected, (state, action) =>
        dietReducerData.requestDataForDietPlanHome(state, action, 'rejected'),
      );

    builder
      .addCase(dietActions.getDietBannerRequest.pending, (state, action) =>
        dietReducerData.requestDataForDietBanners(state, action, 'pending'),
      )
      .addCase(dietActions.getDietBannerRequest.fulfilled, (state, action) =>
        dietReducerData.requestDataForDietBanners(state, action, 'fulfilled'),
      )
      .addCase(dietActions.getDietBannerRequest.rejected, (state, action) =>
        dietReducerData.requestDataForDietBanners(state, action, 'rejected'),
      );

    builder
      .addCase(dietActions.getDietCuratedRestaurantsRequest.pending, (state, action) =>
        dietReducerData.requestDataForDietCuratedRestaurant(state, action, 'pending'),
      )
      .addCase(dietActions.getDietCuratedRestaurantsRequest.fulfilled, (state, action) =>
        dietReducerData.requestDataForDietCuratedRestaurant(state, action, 'fulfilled'),
      )
      .addCase(dietActions.getDietCuratedRestaurantsRequest.rejected, (state, action) =>
        dietReducerData.requestDataForDietCuratedRestaurant(state, action, 'rejected'),
      );

    builder
      .addCase(dietActions.getDietPlanSingleRequest.pending, (state, action) =>
        dietReducerData.requestDataForDietPlan(state, action, 'pending'),
      )
      .addCase(dietActions.getDietPlanSingleRequest.fulfilled, (state, action) =>
        dietReducerData.requestDataForDietPlan(state, action, 'fulfilled'),
      )
      .addCase(dietActions.getDietPlanSingleRequest.rejected, (state, action) =>
        dietReducerData.requestDataForDietPlan(state, action, 'rejected'),
      );

    builder
      .addCase(dietActions.getDietBannerSingleRequest.pending, (state, action) =>
        dietReducerData.requestDataForDietBanner(state, action, 'pending'),
      )
      .addCase(dietActions.getDietBannerSingleRequest.fulfilled, (state, action) =>
        dietReducerData.requestDataForDietBanner(state, action, 'fulfilled'),
      )
      .addCase(dietActions.getDietBannerSingleRequest.rejected, (state, action) =>
        dietReducerData.requestDataForDietBanner(state, action, 'rejected'),
      );

    builder
      .addCase(dietActions.getDietSingleRestaurantRequest.pending, (state, action) =>
        dietReducerData.requestDataForDietSingleRestaurant(state, action, 'pending'),
      )
      .addCase(dietActions.getDietSingleRestaurantRequest.fulfilled, (state, action) =>
        dietReducerData.requestDataForDietSingleRestaurant(state, action, 'fulfilled'),
      )
      .addCase(dietActions.getDietSingleRestaurantRequest.rejected, (state, action) =>
        dietReducerData.requestDataForDietSingleRestaurant(state, action, 'rejected'),
      );
  },
});

export const {
  clearDietPlanHome,
  clearDietBanner,
  clearDietCuratedRestaurants,
  clearDietPlanSingle,
  clearDietBannerSingle,
  clearSingleRestaurant,
} = dietPlanSlice.actions;

export default dietPlanSlice.reducer;
