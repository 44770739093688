const createRequestDataReducer = (key) => (state, action, type = 'fulfilled') => {
    const newState = { ...state[key], loading: false, error: null };
  
    if (type === 'fulfilled') {
      newState.data = action.payload.data;
      newState.message = action.payload.message;
      newState.status = action.payload.status;
    } else if (type === 'pending') {
      newState.loading = true;
      newState.message = 'Not Loaded';
      newState.status = true;
    } else {
      newState.status = false;
      newState.error = action?.error?.message;
      newState.data = action?.error?.message;
    }
  
    return { ...state, [key]: { ...newState } };
  };
  
  export const getFreshListItemsData = (state) => state?.fresh_list?.fresh_list_items;
  export const getFreshItemCategoriesData = (state) => state?.fresh_list?.fresh_list_categories;
  
  export const requestDataForFreshList = createRequestDataReducer('fresh_list_items');
  export const requestDataForFreshItemCategory = createRequestDataReducer('fresh_list_categories');
  