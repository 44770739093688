import { CLUB_STORE_BANNERS } from "./actionTypes";

const initialState = {
    club_store_banners: []
};

export default function(state = initialState, action) {
    // console.log(action.payload, 'payload get club stories');
    if(action.type === CLUB_STORE_BANNERS) {
        return { ...state, club_store_banners: action.payload };
    } else {
        return state;
    }
}
