export const formatPrice = price => {
  try {
    if (price) {
      let formatedPrice = parseFloat(price);
      formatedPrice = formatedPrice.toFixed(2);
      return formatedPrice;
    }
    return 0;
  } catch (error) {
    return 0;
  }
};
