import { USER_CLUB_CARD} from "./actionTypes";

const initialState = {
    user_club_cards: []
};

export default function(state = initialState, action) {
    switch (action.type) {
        case USER_CLUB_CARD:
            return { ...state, user_club_cards: action.payload };
        default:
            return state;
    }
}