import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { WEBSITE_URL } from "../../configs/website";

export const defaultFoodSpots = {
  loading: false,
  data: null,
  message: 'Not Loaded',
  error: null,
  status: false,
};

export const clearDietPlanHome = (state) => {
  return { ...state, dietPlanHome: { ...defaultFoodSpots } };
};

export const clearDietBanner = (state) => {
  return { ...state, dietBanners: { ...defaultFoodSpots } };
};

export const clearDietCuratedRestaurants = (state) => {
  return { ...state, dietCuratedRestaurants: { ...defaultFoodSpots } };
};

export const clearDietPlanSingle = (state) => {
  return { ...state, dietPlan: { ...defaultFoodSpots } };
};

export const clearDietBannerSingle = (state) => {
  return { ...state, dietBanner: { ...defaultFoodSpots } };
};

export const clearSingleRestaurant = (state) => {
  return { ...state, singleRestaurant: { ...defaultFoodSpots } };
};

export const getDietPlanHomeRequest = createAsyncThunk('diet-plan/get-diet-plan-home', async ({ data }) => {
  try {
    const dietPlanHome = await axios.post(`${WEBSITE_URL}/public/api/diet/get-diet-plan-home`, data);
    return { ...dietPlanHome.data };
  } catch (error) {
    return {};
  }
});

export const getDietBannerRequest = createAsyncThunk('diet-plan/get-diet-banner-request', async ({ data }) => {
  try {
    const dietBanners = await axios.post(`${WEBSITE_URL}/public/api/diet/get-diet-banner`, data);
    return { ...dietBanners.data };
  } catch (error) {
    return {};
  }
});

export const getDietCuratedRestaurantsRequest = createAsyncThunk('diet-plan/get-diet-curated-restaurants-request', async ({ data }) => {
  try {
    const dietCuratedRestaurants = await axios.post(`${WEBSITE_URL}/public/api/diet/get-diet-curated-restaurants`, data);
    return { ...dietCuratedRestaurants.data };
  } catch (error) {
    return {};
  }
});

export const getDietPlanSingleRequest = createAsyncThunk('diet-plan/get-diet-plan-single-request', async ({ data }) => {
  try {
    const dietPlan = await axios.post(`${WEBSITE_URL}/public/api/diet/get-diet-plan-single`, data);
    return { ...dietPlan.data };
  } catch (error) {
    return {};
  }
});

export const getDietBannerSingleRequest = createAsyncThunk('diet-plan/get-diet-banner-single-request', async ({ data }) => {
  try {
    const dietBanner = await axios.post(`${WEBSITE_URL}/public/api/diet/get-diet-banner-single`, data);
    return { ...dietBanner.data };
  } catch (error) {
    return {};
  }
});

export const getDietSingleRestaurantRequest = createAsyncThunk('diet-plan/get-single-restaurant-request', async ({ data }) => {
  try {
    const singleRestaurant = await axios.post(`${WEBSITE_URL}/public/api/diet/get-diet-single-restaurant`, data);
    return { ...singleRestaurant.data };
  } catch (error) {
    return {};
  }
});

export const getDietSingleRestaurantItemsRequest = createAsyncThunk('diet-plan/get-single-restaurant-items-request', async ({ data }) => {
  try {
    const singleRestaurant = await axios.post(`${WEBSITE_URL}/public/api/diet/get-diet-single-restaurant-items`, data);
    return { ...singleRestaurant.data };
  } catch (error) {
    return {};
  }
});
