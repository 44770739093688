import React, { useEffect, useState } from 'react';
import AppInstallBadge from './AppInstallBadge';
import { getDevice } from './components/helpers/truncate';
import './logo-anim.css';
function Network(props) {
  const [isOnline, setIsOnline] = useState(true);
  const [isCheckedOffline, setIsCheckedOffline] = useState(true);
  const [speed, setSpeed] = useState(0.00);

  const [locationUpdate, setLocationUpdate] = useState(false);
  const [bottomView, setBottomView] = useState(true);
  const [locationStored, setLocationStored] = useState(true);

  const handleMessage = message => {
    try {
      const data = JSON.parse(message.data);
      if (window.ReactNativeWebView) {
        if (locationUpdate === false && data && data.hasOwnProperty('locationUpdate')) {
          setLocationUpdate(data.locationUpdate == 1 || data.locationUpdate == '1');
        }
      }
    } catch (error) {
    }
    try {
      const response = JSON.parse(message.data);
      if (response && response.appversion) {
        localStorage.setItem("appversion", response.appversion);
      }
    } catch (error) { }
    try {
      const response = JSON.parse(message.data);
      if (response && response.notifyUri && response.notifyUri != 'null' && response.notifyUri != null) {
        const pass_data = {
          type: 'clear_notify',
          data: { clear: true }
        };
        if (window.ReactNativeWebView)
          window.ReactNativeWebView.postMessage(JSON.stringify(pass_data));
        window.location.href = response.notifyUri;
      }
    } catch (error) { }
  }

  const [imgClass, setImgClass] = useState('slide-in-bottom');
  const [img2Class, setImg2Class] = useState('scale-in-center');
  const [divShow, setDivShow] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setImgClass(prevClass => `${prevClass} slide-out-bck-top`);
    }, 1500);
    setTimeout(() => {
      setDivShow(true);
      setImgClass(prevClass => `${prevClass} hidden`);
    }, 2500);
    setTimeout(() => {
      // setDivShow(true);
      setImg2Class(prevClass => `${prevClass} scale-out-center`);
    }, 3500);
    setTimeout(() => {
      // setDivShow(true);
      // setImg2Class(prevClass => `${prevClass} scale-out-center`);
    }, 4500);
    try {
      const address = JSON.parse(localStorage.getItem("userSetAddress"));
      if (address.lat) {
        setLocationStored(true);
      } else {
        setLocationStored(false);
      }
    } catch (error) {
      setLocationStored(false);
    }
    const addEventListener = navigator.appVersion.includes('Android') || navigator.userAgent.includes('Android') ? 'message' : 'message';
    const removeEventListener = navigator.appVersion.includes('Android') || navigator.userAgent.includes('Android') ? 'message' : 'message';

    document.addEventListener(addEventListener, handleMessage);
    window.addEventListener(addEventListener, handleMessage);
    return () => {
      document.removeEventListener(removeEventListener, handleMessage);
      window.removeEventListener(removeEventListener, handleMessage);
    };
  }, []);
  const setwifiSpeed = (speed) => {
    console.log(speed, 'speed');
    setSpeed(speed);
  }
  const getFadeEffect = () => {
    if (locationUpdate) return true;
    else if (locationUpdate && bottomView) return true;
    else return false;
  }
  return (
    // <div>
    //   <ReactInternetSpeedMeter
    //     txtSubHeading={null}
    //     outputType="empty"
    //     customClassName={null}
    //     txtMainHeading=""
    //     pingInterval={4000} // milliseconds 
    //     thresholdUnit='kilobyte' // "byte" , "kilobyte", "megabyte" 
    //     threshold={100}
    //     imageUrl={placeholderImage}
    //     downloadSize="6612"  //bytes
    //     callbackFunctionOnNetworkDown={(speed) => {
    //       setIsOnline(false);
    //       setwifiSpeed(0);
    //       setIsCheckedOffline(false);
    //       console.log(speed, ':- speed is down time');
    //     }}
    //     callbackFunctionOnNetworkTest={(speed) => {
    //       setwifiSpeed(speed);
    //       setIsOnline(true);
    //       setIsCheckedOffline(false);
    //       console.log(speed, ':- speed is test time');
    //     }}
    //     callbackFunctionOnError={(data) => {
    //       setIsOnline(false);
    //       setIsCheckedOffline(false);
    //       console.log(speed, ':- speed is onError time');
    //       console.log(data, 'onError');
    //     }}
    //   />
    //   {isCheckedOffline ?
    //     <div>
    //       <span>Checking Network</span>
    //       <Loading />
    //     </div>
    //     :
    //     isOnline ? (
    //       props?.children
    //     ) : (
    //       <p>Network is offline. Please check your connection.</p>
    //     )
    //   }
    // </div>
    // locationUpdate ?
    //   <AutoLocation
    //     updateStatus={() => {
    //       setLocationUpdate(false);
    //     }}
    //   />
    // :
    <React.Fragment>
      {/* {locationStored ?
        <>
          {locationUpdate ?
            <div style={{
              position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', zIndex: 99997, background: '#fff'
            }}>
              <Loading />
              <AutoLocation
                updateStatus={() => {
                  setLocationUpdate(false);
                  setBottomView(false);
                  setTimeout(() => {
                    if (window.ReactNativeWebView) {
                      const response = {
                        type: 'address_data_off',
                        data: true
                      };
                      window.ReactNativeWebView.postMessage(JSON.stringify(response));
                    }
                    if (document.ReactNativeWebView) {
                      const response = {
                        type: 'address_data_off',
                        data: true
                      };
                      document.ReactNativeWebView.postMessage(JSON.stringify(response));
                    }
                  }, 750);
                }}
                locationUpdate={locationUpdate}
              />
            </div>
            : <></>}
          {getFadeEffect() ?
            <RemoveWhatsAppChatButton />
            : <></>}
        </>
        : <></>} */}
      {/* <div
        style={{ minHeight: '100vh', background: '#fff', }}
        className='d-flex flex-column align-items-center justify-content-center'
      >
        <img
          src={logoBlack}
          className={imgClass}
          style={{
            width: '50%',
          }}
        />
        {divShow ?
          <>
            <div>
              <div
                style={{
                  height: '10rem',
                  width: '10rem',
                  background: '#12121261',
                  borderRadius: '50%',
                }}
                className={img2Class}
              >
              </div>
            </div>
            <span className='slide-in-bottom'>Aksak,a sjasbkasa.sa skanska saslaskansk</span>
          </>
          : <></>}
      </div> */}
      {getDevice() == 'Website' ?
        <AppInstallBadge />
        : <></>}
      {props?.children}
    </React.Fragment>
  );
}

export default Network;