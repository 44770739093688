import { VALIDATE_RESTAURANT_INPUT } from "./actionTypes";

const initialState = {
    validate_input: {},
};

export default function (state = initialState, action) {
    switch (action.type) {
        case VALIDATE_RESTAURANT_INPUT:
            return { ...state, validate_input: action.payload };
        default:
            return state;
    }
}
