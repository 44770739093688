import {
    GET_AD_BANNER_DATA,
    GET_CITY_STORE_TYPES,
    GET_COMING_SOON_DATA,
    GET_COUPON_ITEMS,
    GET_COUPON_RESTAURANT,
    GET_FLASH_DEAL_ITEMS,
    GET_FLASH_DEAL_USERS,
    GET_HOME_FEATURED_RESTAURANT,
    GET_ITEM_GROUP_DATA,
    GET_ITEM_LIST,
    GET_LUNCH_BOX_RESTAURANT,
    GET_OFFER_AMOUNT_ITEMS,
    GET_OFFER_AMOUNT_ITEMS_ITEM,
    GET_OFFER_PERCENTAGE_ITEMS,
    GET_OFFER_PERCENTAGE_ITEMS_ITEM,
    GET_QUICK_BITES,
    GET_STORY_CLUB_RESTAURANT,
    GET_TAG_SLIDERS,
    GET_YUMMY_ITEMS_DATA,
    GET_YUMMY_ITEMS_FRESH_DATA,
    GET_YUMMY_ITEMS_IFTAR_DATA,
    SET_COMING_SOON_NOTIFY_DATA,
    SET_PAGE_USER_COUNT,
} from "./actionTypes";

const initialState = {
    quick_bites: [],
    item_list: [],
    tag_slider: [],
    club_story_restaurant: [],
    update_page: {},
    flash_deal_items: [],
    flash_deal_users: [],
    featured_restos: [],
    offer_percentages: { status: false },
    offer_amount: { status: false },
    offer_amount_items: { status: false },
    offer_percentage_items: { status: false },
    coupon_restaurants: { status: false },
    coupon_items: { status: false },
    city_store_types: { status: false, data: {} },
    lunchbox_restaurant: { status: false, data: {} },
    coming_soon: { status: false, data: [] },
    coming_soon_notify: { status: false, data: [] },
    ad_banner: { status: false, data: [] },
    yummy_items: { status: false, data: [] },
    yummy_items_fresh: { status: false, data: [] },
    yummy_items_iftar: { status: false, data: [] },
    item_groups: {},
};

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_QUICK_BITES:
            return { ...state, quick_bites: action.payload };
        case GET_ITEM_LIST:
            return { ...state, item_list: action.payload };
        case GET_TAG_SLIDERS:
            return { ...state, tag_slider: action.payload };
        case GET_STORY_CLUB_RESTAURANT:
            return { ...state, club_story_restaurant: action.payload };
        case SET_PAGE_USER_COUNT:
            return { ...state, update_page: action.payload };
        case GET_FLASH_DEAL_ITEMS:
            return { ...state, flash_deal_items: action.payload };
        case GET_FLASH_DEAL_USERS:
            return { ...state, flash_deal_users: action.payload };
        case GET_OFFER_PERCENTAGE_ITEMS:
            return { ...state, offer_percentages: action.payload };
        case GET_OFFER_AMOUNT_ITEMS:
            return { ...state, offer_amount: action.payload };
        case GET_OFFER_PERCENTAGE_ITEMS_ITEM:
            return { ...state, offer_percentage_items: action.payload };
        case GET_OFFER_AMOUNT_ITEMS_ITEM:
            return { ...state, offer_amount_items: action.payload };
        case GET_COUPON_RESTAURANT:
            return { ...state, coupon_restaurants: action.payload };
        case GET_COUPON_ITEMS:
            return { ...state, coupon_items: action.payload };
        case GET_CITY_STORE_TYPES:
            return { ...state, city_store_types: action.payload };
        case GET_LUNCH_BOX_RESTAURANT:
            return { ...state, lunchbox_restaurant: action.payload };
        case GET_HOME_FEATURED_RESTAURANT:
            return { ...state, featured_restos: action.payload };
        case GET_COMING_SOON_DATA:
            return { ...state, coming_soon: action.payload };
        case SET_COMING_SOON_NOTIFY_DATA:
            return { ...state, coming_soon_notify: action.payload };
        case GET_AD_BANNER_DATA:
            return { ...state, ad_banner: action.payload };
        case GET_YUMMY_ITEMS_DATA:
            return { ...state, yummy_items: action.payload };
        case GET_YUMMY_ITEMS_FRESH_DATA:
            return { ...state, yummy_items_fresh: action.payload };
        case GET_YUMMY_ITEMS_IFTAR_DATA:
            return { ...state, yummy_items_iftar: action.payload };
        case GET_ITEM_GROUP_DATA:
            return { ...state, item_groups: action.payload };
        default:
            return state;
    }
}
