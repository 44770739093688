import { createBrowserHistory } from "history";
import { AliveScope } from "react-activation";
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import 'react-responsive-modal/styles.css';
import { Route, Routes } from 'react-router-dom';
import 'react-spring-bottom-sheet/dist/style.css';
import { PersistGate } from 'redux-persist/integration/react';
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import NewRoot from './NewRoot';
import * as routeFiles from './RouteFiles';
import "./assets/css/grid.css";
import './index.css';
import reportWebVitals from './reportWebVitals';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { persistor, store } from "./services/store";

const history = createBrowserHistory();

const rootElement = document.getElementById('root') as Element;

const root = createRoot(
  rootElement as HTMLElement
);

// const root = document.getElementById('root') as HTMLElement;
// posthog.init('phc_7zNOZkLtv44SOZdLVocTFV7o1qvnnB9jbdiojW12wBV', { api_host: 'https://us.i.posthog.com' });

const app = (
  // <PostHogProvider client={posthog}>
  <Provider store={store}>
    <PersistGate
      loading={null}
      persistor={persistor}>
      <AliveScope>
        <NewRoot
          history={history}
        >
          <Routes>
            <Route path={'/'} element={<routeFiles.MainApp />}></Route>
            <Route path={'/home'} element={<routeFiles.HomePage />}></Route>
            {/* <KeepAlive> */}
            {/* <Route path={"/food-delivery"} element={
              // <KeepAlive>
              <routeFiles.FoodDelivery />
              // </KeepAlive>
            }></Route> */}
            {/* </KeepAlive> */}
            <Route path={"/our-offers"} element={<routeFiles.OurOffers />}></Route>
            <Route path={"/login"} element={<routeFiles.LoginOtp />}></Route>

            <Route path={"/my-account"} element={<routeFiles.Account />}></Route>
            <Route path={"/my-addresses"} element={<routeFiles.Addresses />}></Route>

            <Route path={"/search-location"} element={<routeFiles.Location />}></Route>
            <Route path={"/my-location"} element={<routeFiles.GeoLocationPage />}></Route>
            <Route path={"/settings"} element={<routeFiles.Settings />}></Route>
            <Route path={"/user/payments"} element={<routeFiles.AllPayments />}></Route>
            <Route path={"/notification"} element={<routeFiles.Alerts />}></Route>
            <Route path={"/dine-in/:likes"} element={<routeFiles.EatikoDineOuts />}></Route>
            <Route path={"/account/rewards"} element={<routeFiles.Rewards />}></Route>
            <Route path={"/my-orders"} element={<routeFiles.Orders />}></Route>
            <Route path={"/rate-order/:id"} element={<routeFiles.RatingAndReview />}></Route>
            <Route path={"/my-wallet"} element={<routeFiles.WalletPage />}></Route>
            <Route path={"/e-coin"} element={<routeFiles.Ecoin />}></Route>
            <Route path={"/user/club-subscription/payment-status/:trans_id"} element={<routeFiles.ClubUserSubscriptionSuccess />}></Route>
            <Route path={"/profile"} element={<routeFiles.Profile />}></Route>
            <Route path={"/running-order/:unique_order_id"} element={<routeFiles.RunningOrder />}></Route>
            <Route path={"/order-success"} element={<routeFiles.RunningOrder />}></Route>
            <Route path={"/my-account-settings"} element={<routeFiles.AccountSettings />}></Route>

            <Route path={"/suggestions"} element={<routeFiles.Suggestions />}></Route>
            <Route path={"/about-us"} element={<routeFiles.About />}></Route>

            <Route path={"/explore"} element={<routeFiles.Explore />}></Route>
            <Route path={"/explore-more"} element={<routeFiles.ExploreMore />}></Route>

            <Route path={'/stores/:restaurant'} element={<routeFiles.SingleRestaurant />}></Route>
            <Route path={'/stores/:restaurant/:id'} element={<routeFiles.SingleItem />}></Route>
            <Route path={'/more-data/:type/:id/:city_id'} element={<routeFiles.MoreDataView />}></Route>
            <Route path={'/list-club-buy'} element={<routeFiles.ClubBuyList />}></Route>

            <Route path={'/recipies'} element={<routeFiles.RecipieRoot />}></Route>
            <Route path={'/recipies/:id'} element={<routeFiles.RecipieSingleRoot />}></Route>
            <Route path={'/recipies/:id/:name'} element={<routeFiles.RecipieSingleRoot />}></Route>
            <Route path={'/recipies/headers/:id'} element={<routeFiles.RecipieHeaderItems />}></Route>
            <Route path={'/recipies/banners/:id'} element={<routeFiles.RecipieBannerItems />}></Route>
            <Route path={'/recipies/cook-by-dish/:dish/:id'} element={<routeFiles.RecipieCookByDishes />}></Route>
            <Route path={'/explore-recipies'} element={<routeFiles.ExploreRecipie />}></Route>
            <Route path={'/all-recipies'} element={<routeFiles.RecipiesAllPage />}></Route>
            <Route path={'/saved-recipies'} element={<routeFiles.SavedRecipies />}></Route>

            <Route path={'/diet'} element={<routeFiles.DietPlan />}></Route>
            <Route path={'/diet/:id'} element={<routeFiles.DietPlanSingle />}></Route>
            <Route path={'/diet-banner/:id'} element={<routeFiles.DietFeatured />}></Route>
            <Route path={'/diet-all'} element={<routeFiles.DietPlanAll />}></Route>
            <Route path={'/diet-explore'} element={<routeFiles.DietExplore />}></Route>
            <Route path={'/diet-restaurants'} element={<routeFiles.DietRestaurantList />}></Route>
            <Route path={'/diet-restaurant/:id'} element={<routeFiles.DietSingleRestaurant />}></Route>

            <Route path={"/cart"} element={<routeFiles.CartPage />}></Route>
            <Route path={"/checkout/"} element={<routeFiles.Checkout />}></Route>
            <Route path={"/checkout/:id"} element={<routeFiles.Checkout />}></Route>
            <Route path={"/coupons"} element={<routeFiles.AvailableCoupons />}></Route>

            <Route path={'/offer-section/:tag'} element={<routeFiles.OfferSection />}></Route>
            <Route path={'/offer-tag/:type/:values'} element={<routeFiles.OfferTag />}></Route>
            <Route path={"/cake"} element={<routeFiles.CakeList />}></Route>

            <Route path={"/dine-in"} element={<routeFiles.EatikoDineOuts />}></Route>
            <Route path={"/dine-in-single-store/:id"} element={<routeFiles.SingleDiningView />}></Route>
            <Route path={"/dine-in-category-wise/:id/:name"} element={<routeFiles.CategoryWiseDineOuts />}></Route>
            <Route path={"/dine-in-collections/:collection_type"} element={<routeFiles.DineoutCollectionStores />}></Route>

            <Route path={"/fresh"} element={<routeFiles.Fresh />}></Route>
            <Route path={'/fresh-list-items/:type'} element={<routeFiles.FreshListItems />}></Route>
            <Route path={"/explore-fresh"} element={<routeFiles.FreshExplore />}></Route>

            <Route path={"/eatiko-look"} element={<routeFiles.EatikoLook />}></Route>
            <Route path={"/eatiko-look/:type"} element={<routeFiles.EatikoLook />}></Route>
            <Route path={"/food-spot-single/:spot_id"} element={<routeFiles.FoodSpotSingle />}></Route>
            <Route path={"/food-spot-single/:spot_id/:name"} element={<routeFiles.FoodSpotSingle />}></Route>
            <Route path={"/explore-eatiko-look"} element={<routeFiles.EatikoLookExplore />}></Route>
            <Route path={"/eatiko-look-likes"} element={<routeFiles.EatikoLookLiked />}></Route>
            <Route path={"/eatiko-look-places"} element={<routeFiles.EatikoLookCities />}></Route>
            <Route path={"/eatiko-look-by-place/:city_name"} element={<routeFiles.EatikoLookByCity />}></Route>
            <Route path={"/eatiko-look-category/:category/:id"} element={<routeFiles.EatikoLookByCategory />}></Route>
            <Route path={"/eatiko-look-verified"} element={<routeFiles.EatikoLookVerified />}></Route>
            <Route path={"/eatiko-look-best-items"} element={<routeFiles.EatikoLookBestItems />}></Route>
            <Route path={"/eatiko-look-top-rated-list"} element={<routeFiles.TopRatedFoodSpotList />}></Route>
            <Route path={"/eatiko-look-review"} element={<routeFiles.ReviewEatikoLook />}></Route>
            <Route path={"/eatiko-look-add-missing-spot"} element={<routeFiles.EatikoLookMissingSptForm />}></Route>

            <Route path='*' element={<routeFiles.NotFound />}></Route>
          </Routes>
        </NewRoot>
      </AliveScope>
    </PersistGate>
  </Provider>
  // </PostHogProvider>
);

// render(app, root);
root.render(app);
// ReactDOM.hydrate(app, rootElement);
// hydrateRoot(rootElement, app);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
