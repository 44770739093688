import { ORDER_CASHFREE_SUCCESS_TOKEN, ORDER_CASHFREE_TOKEN, PLACE_ORDER, UPDATE_CART_ITEMS } from "./actionTypes";

const initialState = {
    checkout: [],
    cashfree_order_token: {},
    cashfree_success: {},
    cart_data: {},
};

export default function (state = initialState, action) {
    switch (action.type) {
        case PLACE_ORDER:
            return { ...state, checkout: action.payload };
        case ORDER_CASHFREE_TOKEN:
            return { ...state, cashfree_order_token: action.payload };
        case ORDER_CASHFREE_SUCCESS_TOKEN:
            return { ...state, cashfree_success: action.payload };
        case UPDATE_CART_ITEMS:
            return { ...state, cart_data: action.payload };
        default:
            return state;
    }
}
