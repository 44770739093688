import axios from "axios";
import { useEffect, useLayoutEffect, useState } from "react";
import { BrowserRouter } from 'react-router-dom';
import { handleClearCache } from "./components/helpers/truncate";

const NewRoot = ({
  history,
  ...props
}) => {
  const [state, setState] = useState({
    action: history.action,
    location: history.location
  });

  useLayoutEffect(() => history.listen(setState), [history]);

  const updateCahce = (callBack = undefined) => {
    axios.get("/version.json").then((response) => {
      const forceNewSettingsServerVersion = response.data.forceNewSettingsVersion;
      const forceCacheClearServerVersion = response.data.forceCacheClearVersion;
      if (
        localStorage.getItem("forceNewSettingsVersion") !== forceNewSettingsServerVersion &&
        localStorage.getItem("forceCacheClearVersion") !== forceCacheClearServerVersion
      ) {
        localStorage.setItem("forceNewSettingsVersion", forceNewSettingsServerVersion);
        localStorage.setItem("forceCacheClearVersion", forceCacheClearServerVersion);
        console.log('version reloaded. set');
        try {
          handleClearCache();
        } catch (error) {
          window.location.reload();
        }
        if (callBack) callBack();
      }
    })
      .catch(() => {
        if (callBack) callBack();
      });
  };

  useEffect(() => {
    updateCahce();
  }, []);

  return (
    <BrowserRouter
      {...props}
      location={state.location}
      navigationType={state.action}
      navigator={history}
      updateCahce={updateCahce}
    >
      {props?.children}
    </BrowserRouter>
  );
};

export default NewRoot;
